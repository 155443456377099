function Enroll(){
    return(
        <>
        <div className=""  >  
<div className="bg-[#FFFFFF]">
 
 
</div>
        </div>
        </>
    )



}
export default Enroll;