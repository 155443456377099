import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { postApiCall } from "../Services/Api_services";
import constant from "../Services/constant.json";
import CustomSnackbar from "../Components/CustomSnackbar";
import { Box } from "@mui/system";

function Login(props) {
  const navigate = useNavigate();
  const host = window.location.host;
  const pathName = window.location.pathname;
  const url = `${host}${pathName}`;
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });
  const [userName, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [agree, setAgree] = React.useState(false);

  const [isClicked, setClicked] = React.useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const checked = () => {
    setAgree(!agree);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function adminLogin() {
    setClicked(true);
    const body = {
      username: userName,
      password: values?.password,
    };
    const url = `${constant.baseURL}/admins/login`;
console.log(url)
    const admin = await postApiCall(url, body);
    if (admin?.id) {
      localStorage.setItem("ww_ref_token", JSON.stringify(admin?.id));
      localStorage.setItem("ww_ref_userId", JSON.stringify(admin?.userId));
      localStorage.setItem("ww_ref_role", JSON.stringify("admin"));
      setSnackbar({
        openSnackbar: true,
        message: "Login successful",
        severity: "success",
      });
      navigate("/admin/cases");
      setClicked(false);
      props?.refreshToken();
    } else {
      setClicked(false);
      setSnackbar({
        openSnackbar: true,
        message: "Please enter correct username or password",
        severity: "error",
      });
    }
  }

  async function  ambassadorLogin() {
    setClicked(true);
    const body = {
      email: email,
      password: values?.password,
    };
    const url = `${constant.baseURL}/ambassadors/login`;
    const ambassador = await postApiCall(url, body);
    if (ambassador?.id) {
      localStorage.setItem("ww_ref_token", JSON.stringify(ambassador?.id));
      localStorage.setItem("ww_ref_userId", JSON.stringify(ambassador?.userId));
      localStorage.setItem("ww_ref_role", JSON.stringify("ambassador"));
      setSnackbar({
        openSnackbar: true,
        message: "Login successful",
        severity: "success",
      });
      setClicked(false);
      navigate(`/ambassador/${ambassador?.userId}`);
      props?.refreshToken();
    } else {
      setClicked(false);
      setSnackbar({
        openSnackbar: true,
        message: "Please enter correct email or password",
        severity: "error",
      });
    }
  }

  return (
    <div className="bg-slate-100 h-screen w-screen flex items-center justify-center">
      <div className="bg-white h-fit px-4 py-3 shadow-md rounded-md w-96 sm:mx-0 mx-4">
        {url === `${host}/admin` ||
        url === `${host}/admin/` ||
        url === `${host}/admin/login` ? (
          <h1>WeightWonder Admin Login</h1>
        ) : (
          <h1>WeightWonder Ambassador Login</h1>
        )}
        <div className="mt-4">
          {url === `${host}/admin` ||
          url === `${host}/admin/` ||
          url === `${host}/admin/login` ? (
            <FormControl fullWidth>
              <TextField
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                autoFocus
                id="outlined-basic"
                label="Username"
                variant="outlined"
              />
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />
            </FormControl>
          )}
          <FormControl className="mt-7" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onKeyDown={(e) =>
                (e.key === "Enter" && url === `${host}/admin`) ||
                url === `${host}/admin/` ||
                url === `${host}/admin/login`
                  ? adminLogin()
                  : e.key === "Enter" && url === `${host}/ambassador-login`
                  ? ambassadorLogin()
                  : null
              }
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </div>
        <div className="flex justify-end mt-5   h-9">
          {(url === `${host}/ambassador` || url === `${host}/ambassador/`) && (
            <div className="flex gap-2  ">
              <input type="checkbox" className="-mt-4" onClick={checked} />
              <p className="text-sm text-blue-400 leading-none">
                I have read and agree to the terms of service
              </p>
            </div>
          )}
          {!isClicked ? (
            url === `${host}/admin` ||
            url === `${host}/admin/` ||
            url === `${host}/admin/login` ? (
              <Button
                onClick={adminLogin}
                disabled={
                  userName?.length > 0 && values?.password?.length > 0
                    ? false
                    : true
                }
                className="w-36"
                variant="contained"
              >
                Login
              </Button>
            ) : (
              <Button
                onClick={ambassadorLogin}
                disabled={
                  email?.length > 0 && values?.password?.length > 0 && agree
                    ? false
                    : true
                }
                className="w-36"
                variant="contained"
              >
                Login
              </Button>
            )
          ) : (
            <Box className="w-36 flex items-center justify-center">
              <CircularProgress size={25} />
            </Box>
          )}
        </div>
        <CustomSnackbar
          openSnackbar={snackbar.openSnackbar}
          closeSnackbar={() =>
            setSnackbar({
              openSnackbar: false,
              message: "",
              severity: "",
            })
          }
          severity={snackbar.severity}
          message={snackbar.message}
        />
      </div>
    </div>
  );
}
export default Login;
