import {
    Button,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    InputAdornment,

} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import constant from "../Services/constant.json";
import { getApiCall, postApiCall } from "../Services/Api_services";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../Components/CustomSnackbar";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import moment from "moment/moment";

function Form(props) {
    const [pathname, setPathname] = React.useState(decodeURIComponent(window?.location?.pathname?.substring(1)));
    const navigate = useNavigate();
    const [path, setPath] = React.useState('')
    const [emailErr, setEmailErr] = React.useState(false)
    const [couponCodeErr, setCouponCodeErr] = React.useState(false)
    const [phoneErr, setPhoneErr] = React.useState(false)
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [DOB, setDOB] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [couponCode, setCouponCode] = React.useState("");
    const [phoneExist, setPhoneExist] = React.useState(false);
    const [emailExist, setEmailExist] = React.useState(false);
    const [loading,setLoading]=React.useState(false)
    const StringWithSpace = new RegExp(/^[a-zA-Z]+.[a-zA-Z ]+$/);
    const date = new Date()
    const newDate = moment(date.setFullYear(date.getFullYear() - 13)).format('YYYY-MM-DD')
    const [minDate, setMinDate] = React.useState(newDate)
    const [snackbar, setSnackbar] = React.useState({
        openSnackbar: false,
        message: '',
        severity: ''
    });

    const [discount, setDiscount] = React.useState(props.discount)
    const [debouncedClick, setDebouncedClick] = React.useState(null);


    // async function getDiscount(e) {
    //     try {
    //         const value = props.path;
    //         const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${value}"}}`;
    //         const couponcode = await getApiCall(url)
    //         if (couponcode && couponcode[0].discount) {
    //             setDiscount(couponcode[0].discount)
    //         } else {
    //             console.log("no discount");
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    React.useEffect(() => {
        // getDiscount();
        if (pathname) {
            const updatedCouponCode = pathname.toUpperCase();
            console.log(updatedCouponCode)
            setCouponCode(updatedCouponCode);
            // changeCoupon()
            const coupon = couponCode || updatedCouponCode
            console.log(couponCode)
            if (coupon) {
                verifyCoupon(coupon)

            }
        }

    }, [pathname])
    React.useEffect(() => {
        if (!discount) {
            setDiscount(props?.discount)
        }
    }, [props?.discount])
    const verifyCoupon = async (pathname) => {
        const url = encodeURI(`${constant.baseURL}/ambassadors/count?where={"couponCode":"${couponCode || pathname}"}`)

        const res = await getApiCall(url)
        if (res?.count === 1) {
            setPath(couponCode || pathname)
            setCouponCodeErr(false)
            setSnackbar({
                openSnackbar: true,
                message: 'Coupon applied successfully',
                severity: 'success'
            })
            const disUrl = encodeURI(`${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${couponCode || pathname}"}}`);
            const couponForDis = await getApiCall(disUrl)
            if (couponForDis && couponForDis[0].discount) {
                setDiscount(couponForDis[0].discount)
                props?.getAmbassador()
                props?.couponcb(pathname)

            } else {
                console.log("no discount");
            }
        }
        else {

            setSnackbar({
                openSnackbar: true,
                message: 'Invalid Coupon',
                severity: 'error'
            })
        }
    }


    const theme = createTheme({
        components: {
            // Name of the component
            MuiInput: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        color: DOB ? "black" : "gray",
                        required: true,
                        textTransform: "uppercase",
                    },
                },
            },
        },
    });
    const debounce = (func, delay) => {
        clearTimeout(debouncedClick);
        const newDebouncedClick = setTimeout(func, delay);
        setDebouncedClick(newDebouncedClick);
    };
    const submit = React.useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true)
                const body = {
                    name: name,
                    phone: phone,
                    email: email,
                    dob: DOB,
                    gender: gender,
                    couponCode: path,
                };
                const emailAlreadyExist = await emailExistErr();
                const mobAlreadyExist = await phoneExistErr();
                if (emailExist) {
                    setSnackbar({
                        openSnackbar: true,
                        message: 'Email already registered',
                        severity: 'error'
                    })
                    return
                }
                if (phoneExist) {
                    setSnackbar({
                        openSnackbar: true,
                        message: 'Phone already registered',
                        severity: 'error'
                    })
                    return
                }
                if (!emailAlreadyExist && !mobAlreadyExist) {
                    const url = `${constant.baseURL}/cases`;
                    const addCase = await postApiCall(url, body);
                    setLoading(false)
                    if (addCase?.id) {
                        reset();
                        navigate("/thankyou");
                    }
                    else {
                        setSnackbar({
                            openSnackbar: true,
                            message: 'Internal server error',
                            severity: 'error'
                        })
                    }
                }
            } catch (e) {
                setLoading(false)
                reject(e)
            }

        }
        )
    }, [name, phone, email, DOB, gender, path])
    function reset() {
        setName("");
        setPhone("");
        setEmail("");
        setDOB("");
        setGender("");
        setCouponCode("");
        setPathname('')
    }

    async function changeCoupon(e) {
        const url = encodeURI(`${constant.baseURL}/ambassadors/count?where={"couponCode":"${couponCode}"}`)
        const res = await getApiCall(url)
        if (res?.count === 1) {
            setCouponCodeErr(false)
            setPath(couponCode)
            navigate(`/${couponCode}`)
            setSnackbar({
                openSnackbar: true,
                message: 'Coupon applied successfully',
                severity: 'success'
            })
            const disUrl = encodeURI(`${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${couponCode}"}}`);
            const couponForDis = await getApiCall(disUrl)
            if (couponForDis && couponForDis[0].discount) {
                setDiscount(couponForDis[0].discount)
                props?.getAmbassador()
                props?.couponcb(couponCode)

            } else {
                console.log("no discount");
            }
        }
        else {
            setCouponCodeErr(true)
            toast("coupon does not exist !")
        }
    }

    function changePhone(e) {
        setPhoneExist(false)
        setPhone(e.target.value)
        if (e.target.value?.length < 6 || e.target.value?.length > 15) {
            setPhoneErr(true)
        }
        else {
            setPhoneErr(false)
        }
    }
    async function phoneExistErr() {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(phone)
                const url = `${constant.baseURL}/cases?filter={"where":{"phone":"${phone}"}}`
                const phoneexist = await getApiCall(url)
                if (phoneexist.length > 0) {
                    setPhoneExist(true)
                }
                else {
                    setPhoneExist(false)
                    resolve(false);

                }
            }
            catch (e) {
                reject(e)
            }
        })
    }
    async function emailExistErr() {
        return new Promise(async (resolve, reject) => {
            try {
                if (email) {
                    const url = `${constant.baseURL}/cases?filter={"where":{"email":"${email}"}}`
                    const emailexist = await getApiCall(url)
                    if (emailexist.length > 0) {
                        setEmailExist(true)
                    }
                    else {
                        setEmailExist(false)
                        resolve(false);

                    }
                }
                else {
                    resolve(false)
                }
            }
            catch (e) {
                reject(e)
            }
        })
    }
    function changeEmail(e) {
        setEmailExist(false)
        setEmail(e.target.value)
        const email = validateEmail(e.target.value)
        if (email) {
            setEmailErr(false)
        }
        else {
            setEmailErr(true)
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) || email === "";
    };

    return (
        <>
            <div className='rounded-sm flex flex-col items-center'>
                {/* <h1 className='text-[#3F3F3F] font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get <span className='font-bold text-[4vmax] lg:text-[3vmax] italic'>₹ {discount && discount}</span> Flat Discount</h1>
                    <h1 className='text-[#3F3F3F] font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get Offer</h1> */}
                {/* {discount ? <h1 className='text-[#3F3F3F] font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get <span className='font-bold text-[4vmax] lg:text-[3vmax] italic'>₹ {discount && discount}</span> Flat Discount</h1> : <h1 className='text-[#3F3F3F] font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get Offer</h1>} */}


                {/* {props.path ? (
                        <h6 className='text-[#3F3F3F] font-[Qanelas] font-semibold text-[1.7vmax] lg:text-[1.1vmax]'>Coupen Code Applied</h6>
                    ) : (
                        <h6 className='text-[#3F3F3F] font-[Qanelas] font-semibold text-[1.7vmax] lg:text-[1.1vmax]'>Use code : <span className='text-[#F292A2] font-normal'>WW123478</span></h6>
                    )} */}

                <div className="">
                    {!path &&
                        <>
                            <h1 className='text-[#3F3F3F] text-center font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get Offer</h1>
                            <div className="px-4 py-2 w-80 flex items-center justify-center">
                                <TextField
                                    size="small"
                                    id="1"
                                    placeholder="Apply Coupon code"
                                    variant="outlined"
                                    // value={couponCode}
                                    // onChange={changeCoupon}
                                    onChange={(e) => { setCouponCode(e.target.value.toUpperCase()); }}
                                    // value={props.path}
                                    sx={{
                                        color: 'rgba(55, 55, 55, 0.20)',
                                        '& .MuiInputBase-root': {
                                            [theme.breakpoints.down('sm')]: {
                                                fontSize: '2vmax', // Font size for large screens
                                            },
                                            [theme.breakpoints.up('lg')]: {
                                                fontSize: '.8vmax', // Font size for large screens
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {couponCode && <Button variant="text" size="small" className="bg-[#fff] text-[black]" onClick={changeCoupon}>
                                                    Apply
                                                </Button>}
                                                <ToastContainer />
                                            </InputAdornment>

                                        ),
                                    }}
                                />
                            </div>
                        </>}
                </div>
                <div className="flex flex-col  items-center justify-center">
                    {path && <>
                        <h1 className='text-[#3F3F3F] font-[Qanelas] font-normal text-[3vmax] lg:text-[2vmax]'>Get <span className='font-bold text-[4vmax] lg:text-[3vmax] italic'>{props?.currencyAbbr} {discount?.toFixed(2) || "0"}</span> Flat Discount</h1>
                        <TextField size="small"
                            id="1"
                            fullWidth
                            placeholder="Coupon code"
                            variant="outlined"
                            value={path}
                            sx={{
                                color: 'rgba(55, 55, 55, 0.20)',
                                '& .MuiInputBase-root': {
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '2vmax', // Font size for large screens
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '.8vmax', // Font size for large screens
                                    },
                                },
                            }}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="text" size="small" className="bg-[#fff] text-[black] cursor-default">
                                            Applied
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </>}
                </div>
                <small className='text-[#0453BC] font-[Qanelas] text-[1.9vmax] font-semibold tracking-wide lg:text-[1.2vmax]'>And get 2 months free trial !</small>
            </div>
            <div className='w-full flex flex-col items-start gap-4 px-4 py-4 lg:flex-row lg:flex-wrap lg:items-center lg:justify-center'>

                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'>




                    <h6 className='text-[#3F3F3F] font-[Qanelas] text-[1.7vmax] lg:text-[1vmax]'>Name<span className='text-red-600'>*</span></h6>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            required
                            id="1"
                            placeholder="Name"
                            variant="outlined"
                            value={name}
                            sx={{
                                color: 'rgba(55, 55, 55, 0.20)',
                                '& .MuiInputBase-root': {
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '2vmax', // Font size for large screens
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '.8vmax', // Font size for large screens
                                    },
                                },
                            }}
                            onChange={(e) => setName(e.target.value)}
                            error={
                                name?.length > 0 &&
                                !StringWithSpace?.test(name)
                            }
                            helperText={
                                name?.length > 0 &&
                                    !StringWithSpace?.test(name)
                                    ? "enter valid name"
                                    : ""
                            }
                        />
                    </FormControl>
                </div>
                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'>
                    <h6 className='text-[#3F3F3F] font-[Qanelas] text-[1.7vmax] lg:text-[1vmax]'>Phone <span className='text-red-600'>*</span></h6>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            required
                            id="1"

                            placeholder="Phone"
                            variant="outlined"
                            value={phone}
                            type="number"
                            onChange={changePhone}
                            onBlur={phoneExistErr}
                            sx={{
                                color: 'rgba(55, 55, 55, 0.20)',
                                '& .MuiInputBase-root': {
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '2vmax', // Font size for large screens
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '.8vmax', // Font size for large screens
                                    },
                                },
                            }}
                        />
                        {phoneErr && <FormHelperText className='text-red-600'>
                            Phone no. should be of 6 to 15 digits.
                        </FormHelperText>}
                        {phoneExist && <FormHelperText className='text-red-600'>
                            Phone no. already exist
                        </FormHelperText>}
                    </FormControl>
                </div>
                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'>
                    <h6 className='text-[#3F3F3F] font-[Qanelas] text-[1.7vmax] lg:text-[1vmax]'>Email Id</h6>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            id="1"
                            placeholder="Email"
                            variant="outlined"
                            value={email}
                            onChange={changeEmail}
                            onBlur={emailExistErr}
                            sx={{
                                color: 'rgba(55, 55, 55, 0.20)',
                                '& .MuiInputBase-root': {
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: '2vmax', // Font size for large screens
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        fontSize: '.8vmax', // Font size for large screens
                                    },
                                },
                            }}
                        />
                        {emailErr && <FormHelperText className='text-red-600'>
                            Email is not valid.
                        </FormHelperText>}

                        {emailExist && <FormHelperText className='text-red-600'>
                            Email already exist
                        </FormHelperText>}
                    </FormControl>
                </div>
                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'>
                    <h6 className='text-[#3F3F3F] font-[Qanelas] text-[1.7vmax] lg:text-[1vmax]'>DOB <span className='text-red-600'>*</span></h6>
                    <ThemeProvider theme={theme}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                required
                                InputLabelProps={{ shrink: true }}
                                id="1"
                                variant="outlined"
                                type="date"
                                value={DOB}
                                placeholder="DD/MM/YY"
                                onChange={(e) => setDOB(e.target.value)}
                                InputProps={
                                    {
                                        inputProps: { max: minDate }
                                    }
                                }

                                sx={{
                                    color: 'rgba(55, 55, 55, 0.20)',
                                    '& .MuiInputBase-root': {
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '2vmax', // Font size for large screens
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            fontSize: '.8vmax', // Font size for large screens
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                    </ThemeProvider>
                </div>
                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'>
                    <h6 className='text-[#3F3F3F] font-[Qanelas] text-[1.7vmax] lg:text-[1vmax]'>Gender <span className='text-red-600'>*</span></h6>
                    <FormControl fullWidth>
                        <Select
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={gender}
                            placeholder="Gender"
                            size="small"
                            displayEmpty
                            onChange={(e) => setGender(e.target.value)}
                        >
                            <MenuItem value="">
                                <h6 className="text-[#3F3F3F8F] font-[Qanelas] text-[2vmax] lg:text-[.8vmax] ">Gender</h6>
                            </MenuItem>
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='w-full flex flex-col items-start gap-2 lg:w-[45%]'></div>


                <div className="flex justify-end mt-7 w-full">
                    <div className='flex items-end justify-end w-full px-4'>
                        <Button className='bg-[#3F3F3F] px-6 py-2 rounded-sm disabled:opacity-50' disabled={name && phone && DOB && gender && !couponCodeErr && !phoneErr && !emailErr && !emailExist && !phoneExist && !loading ? false : true} 
                        //  onClick={() => debounce(submit, 1000)}
                           onClick={submit}

                          variant="contained" size="small">
                            <h6 className='text-white font-[Qanelas] text-[1.7vmax] lg:text-[.8vmax]'>SUBMIT</h6>
                        </Button>
                    </div>
                </div>
                <CustomSnackbar
                    openSnackbar={snackbar.openSnackbar}
                    closeSnackbar={() => setSnackbar({
                        openSnackbar: false,
                        message: '',
                        severity: ''
                    })}
                    severity={snackbar.severity}
                    message={snackbar.message}
                />
            </div>
            {/* <h1 className="text-gray-500 text-xl">Add case</h1> */}

        </>
    );
}

export default Form;
