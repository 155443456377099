import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Outlet } from 'react-router-dom';
import HeaderLogo from '../assets/image 7.png'
import constant from "../Services/constant.json";
import { getApiCall, postApiCall } from "../Services/Api_services";
const drawerWidth = 240;
// const navItems = ['About us', 'Contact us']

function Layout(props) {
    const { win } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [logo, setLogo] = React.useState(props.ambassador.logo);
    // const [name, setName] = React.useState(props.ambassador.name);

    // const setDetail=()=>{
    //     if (props.ambassador) {
    //         setLogo(props.ambassador?.logo)
    //         setName(props.ambassador?.name)
    //     }
    // }
    // React.useEffect(() => {
    //     if (props.ambassador) {
    //         setLogo(props.ambassador?.logo)
    //         setName(props.ambassador?.name)
    //     }
    // }, [props.ambassador])

    // async function getLogo(e) {
    //     try {
    //         const value = props.path;
    //         const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${value}"}}`;
    //         const userLogo = await getApiCall(url)
    //         const IMG_URL = constant.imageBaseURL + userLogo[0].logo
    //         setLogo(IMG_URL)

    //     } catch (e) {    
    //         console.log(e);
    //     }
    // }

    // React.useEffect(() => {
    //     getLogo();
    // }, [])

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //         <div className='flex  gap-2'>
    //             <Typography variant="h6" sx={{ my: 2 }}>
    //                 {logo ? <img src={constant.imageBaseURL + logo} alt="HeaderLogo" className='h-12' /> :''}


    //             </Typography>
    //             <p className='text-[#262626] text-lg my-auto'>{name}</p>
    //         </div>
    //         <Divider />

    //     </Box>
    // );

    const container = win !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {props?.ambassador?.name &&
                    <div>
                        <CssBaseline />
                        <AppBar component="nav" className='!shadow-none bg-[#ffffff]'>
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: 'none' }, }}
                                >
                                    <MenuIcon sx={{ color: '#3F3F3F' }} />
                                </IconButton>
                                {props?.ambassador?.logo ?
                                    <div className='flex gap-2 items-center '>
                                        <img src={`${constant.imageBaseURL}${props?.ambassador?.logo}`} alt="logo" className='h-12' />

                                        <p className='text-[#262626] text-lg my-auto'>{props?.ambassador?.name}</p>
                                    </div>
                                    : <p className='text-[#262626] text-lg my-auto'>{props?.ambassador?.name}</p>

                                }

                            </Toolbar>
                        </AppBar></div>

                }
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {/* {drawer} */}
                    </Drawer>
                </nav>
            </Box>
            <Outlet />
        </>
    );
}

Layout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Layout;
