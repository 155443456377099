import * as React from "react";
import { Button, DialogActions, FormControl, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import constant from "../Services/constant.json";
import { postApiCall } from "../Services/Api_services";
import CustomSnackbar from "../Components/CustomSnackbar";

function PaymentDialog(props) {
  const [amount, setAmount] = React.useState("");
  const adminId = JSON.parse(localStorage.getItem("ww_ref_userId"));
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [conformationpayment, setConformationpayment] = React.useState(false);
  const [debouncedClick, setDebouncedClick] = React.useState(null);
  const [payoutLoading, setPayoutLoading] = React.useState(false);

  React.useEffect(() => {
    if (props?.paymentDialog) {
      setAmount(props?.walletAmount?.toFixed(2));
    }
    // eslint-disable-next-line
  }, [props?.paymentDialog]);

  function onCancelAction() {
    setAmount("");
    props?.closeDialog();
  }

  const debounce = (func, delay) => {
    clearTimeout(debouncedClick);
    const newDebouncedClick = setTimeout(func, delay);
    setDebouncedClick(newDebouncedClick);
  };
  const pay = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        setPayoutLoading(true);
        const body = {
          amount: amount,
          ambassadorId: props?.ambassadorId,
          txnType: "debit",
          adminId: adminId,
        };
        const url = `${constant.baseURL}/transactions/payout`;
        const payout = await postApiCall(url, body);
        setPayoutLoading(false);
        if (payout?.statusCode === 200) {
          setSnackbar({
            openSnackbar: true,
            message: "Payment paid successfully",
            severity: "success",
          });
          setConformationpayment(false);
          onCancelAction();
          props?.reloadData();
          props?.getTransactions();
        } else {
          setSnackbar({
            openSnackbar: true,
            message: "Internal server error",
            severity: "error",
          });
        }
      } catch (error) {
        setPayoutLoading(false);
        reject(error);
      }
    });
  }, [amount, adminId, props]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" open={conformationpayment}>
        <DialogTitle className="themeColor text-white">
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent className="">
          <div>
            <h1>Are you sure to withdraw the amount?</h1>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button
            onClick={() => setConformationpayment(false)}
            variant="outlined">
            No
          </Button>
          &nbsp;
          <Button
            disabled={payoutLoading}
            onClick={() => pay()}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} maxWidth="sm" open={props?.paymentDialog}>
        <DialogTitle className="themeColor text-white">WITHDRAW</DialogTitle>
        <Divider />
        <DialogContent className="pb-10">
          <div>
            <FormControl fullWidth>
              <TextField
                required
                id="2"
                // type='number'
                label="Amount"
                variant="standard"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormControl>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button onClick={onCancelAction} variant="outlined">
            Cancel
          </Button>
          &nbsp;
          <Button
            disabled={
              amount > props?.walletAmount || amount === "" || amount < 1
                ? true
                : false
            }
            onClick={() => {
              props?.closeDialog();
              setConformationpayment(true);
            }}
            variant="contained"
          >
            Pay
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        openSnackbar={snackbar.openSnackbar}
        closeSnackbar={() =>
          setSnackbar({
            openSnackbar: false,
            message: "",
            severity: "",
          })
        }
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
}
export default PaymentDialog;
