import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dynamictable from "../Components/DynamicTable";
import AddIcon from "@mui/icons-material/Add";
import AmbassadorDialog from "../Popups/AmbassadorDialog";
import * as React from "react";
import constant from "../Services/constant.json";
import { getApiCall } from "../Services/Api_services";
import SearchIcon from "@mui/icons-material/Search";
import debounce from "lodash/debounce";
function Ambassadors() {
  const [allAmbassadorArr, setAllAmbassadorArr] = React.useState(null);
  const [ambassadorDialog, setAmbassadorDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState("");

  const containerRef = React.useRef(null);
  React.useEffect(() => {
    if (search?.length <= 0) {
      fetchCount();
    }
    getAllAmbassadors();
    // eslint-disable-next-line
  }, [rowsPerPage, search]);
  React.useEffect(() => {
    const token = localStorage.getItem("ww_ref_token");
    console.log(token);
    if (!token) {
      console.log("token", token);
      // navigate("/admin")
      window.location.replace("/admin");
    }
  }, []);
  async function fetchCount() {
    const where = `{
      "or": [
        {
            "name": {
                "like": "${search}",
                "options": "i"
            }
        },
        {
            "email": {
                "like": "${search}",
                "options": "i"
            }
        },
        {
            "phone": {
                "like": "${search}",
                "options": "i"
            }
        }
      ]
    }`;
    const url = `${constant.baseURL}/ambassadors/count${
      search ? `?where=${where}` : ""
    }`;
    const ambassadors = await getApiCall(url);
    setTotal(ambassadors.count);
  }

  function reloadTable() {
    fetchCount();
    getAllAmbassadors();
  }

  async function getAllAmbassadors(skip, limit, newPage) {
    const where = ` "where": {
      "or": [
          {
              "name": {
                  "like": "${search}",
                  "options": "i"
              }
          },
          {
              "couponCode": {
                  "like": "${search}",
                  "options": "i"
              }
          },
          {
              "email": {
                  "like": "${search}",
                  "options": "i"
              }
          },
          {
              "phone": {
                  "like": "${search}",
                  "options": "i"
              }
          }
      ]
  },`;
    const filter = `{
      ${search ? where : ""}
      "order": "createdAt DESC",
      "skip": ${skip ? skip : 0},
      "limit": ${limit ? limit : rowsPerPage}
    }`;
    const url = `${constant.baseURL}/ambassadors?filter=${filter}`;
    const ambassadors = await getApiCall(url);
    if (search) {
      setTotal(ambassadors.length);
    }
    let arr = [];
    if (ambassadors.length === 0) setAllAmbassadorArr([]);
    for (let index = 0; index < ambassadors.length; index++) {
      const element = ambassadors[index];
      const dateString = element?.createdAt;
      const D = new Date(dateString);
      const result =
        D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
      arr.push(
        createData(
          index + 1,
          element?.name,
          element?.phone,
          element?.email,
          element?.currencyAbbr,
          element?.couponCode,
          element?.discount?.toFixed(2),
          element?.defReferralAmt?.toFixed(2),
          element?.altReferralAmt?.toFixed(2),
          element?.reEnrollReferralFactor,
          result,
          element?.wallet?.toFixed(2),
          element?.id
        )
      );
      if (index === ambassadors.length - 1) {
        setAllAmbassadorArr(arr);
        setPage(newPage ? newPage : 0);
      }
    }
  }

  const handleClickOpen = () => {
    setAmbassadorDialog(true);
  };

  const handleClose = () => {
    setAmbassadorDialog(false);
  };

  const navigate = useNavigate();

  const headCells = [
    {
      id: "first",
      numeric: true,
      disablePadding: false,
      label: "Sno",
    },
    {
      id: "second",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "third",
      numeric: true,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "fourth",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "fifth",
      numeric: false,
      disablePadding: false,
      label: "Currency",
    },
    {
      id: "sixth",
      numeric: true,
      disablePadding: false,
      label: "Coupon code",
    },
    {
      id: "seventh",
      numeric: true,
      disablePadding: false,
      label: "Discount",
    },
    {
      id: "eighth",
      numeric: true,
      disablePadding: false,
      label: "Default referral Amt",
    },
    {
      id: "ninth",
      numeric: true,
      disablePadding: false,
      label: "Alternate referral Amt",
    },
    {
      id: "tenth",
      numeric: true,
      disablePadding: false,
      label: "Re-enrollment factor",
    },
    {
      id: "eleventh",
      numeric: true,
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "twelth",
      numeric: true,
      disablePadding: false,
      label: "Wallet",
    },
  ];

  function createData(
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eighth,
    ninth,
    tenth,
    eleventh,
    twelth,
    id
  ) {
    return {
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eighth,
      ninth,
      tenth,
      eleventh,
      twelth,
      id,
    };
  }

  function rowClick(row, index) {
    navigate(`/admin/AmbassadorDetails/${row?.id}`);
  }

  const handleChangePage = (event, newPage) => {
    const _skip = newPage * rowsPerPage;
    const limit = rowsPerPage;
    getAllAmbassadors(_skip, limit, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // Debounce function with a delay of 300ms
  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
  }, 300);

  const handleInputChange = (event) => {
    const { value } = event.target;
    // Call the debounced setSearch function
    debouncedSetSearch(value);
  };
  return (
    <div className="m-5 flex flex-col flex-1 gap-2">
      <div className="flex justify-between items-center">
        <h1 className="text-lg text-gray-500">Ambassadors</h1>
        <FormControl className="searchBar " autocomplete="off">
          <TextField
            size="small"
            sx={{ backgroundColor: "white" }}
            InputProps={{
              autocomplete: "off",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="sm" autoComplete="off" />
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            // onChange={(ev) => setSearch(ev.target.value)}
            onChange={handleInputChange}
          />
        </FormControl>
      </div>
      <div className="flex-1 flex flex-col" ref={containerRef}>
        {allAmbassadorArr ? (
          allAmbassadorArr?.length > 0 ? (
            <Dynamictable
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
              rowClick={(row, index) => rowClick(row, index)}
              heading={headCells}
              rowdata={allAmbassadorArr}
              totalData={total}
            />
          ) : (
            <div className="h-96 flex items-center justify-center">
              <p className="text-lg">No ambassadors yet!</p>
            </div>
          )
        ) : (
          <div className="h-96 flex items-center justify-center">
            <CircularProgress size={30} />
          </div>
        )}
      </div>
      <Button
        sx={{
          borderRadius: "50px",
          height: "60px",
          width: "60px",
          position: "fixed",
          bottom: "70px",
          right: "40px",
          zIndex: 200,
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ fontSize: "30px" }} />
      </Button>
      <AmbassadorDialog
        reloadData={reloadTable}
        ambassadorDialog={ambassadorDialog}
        handleClose={handleClose}
      />
    </div>
  );
}
export default Ambassadors;
