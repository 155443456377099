import React from "react";
import DB from "../assets/DB.png";
import TOI from "../assets/TOI.png";
import DBS from "../assets/DBS.png";
import Patrika from "../assets/Patrika.png";
import FooterBottom from "../assets/FooterBottom.png";

const Footer = () => {
  return (
    <>
      <div className="w-full bg-[#F3F1EE] flex flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center justify-center py-3 px-5 lg:py-6">
          <h1 className="text-[#3F3F3F] font-[Qanelas] text-[4vmax] font-semibold lg:text-[2vmax]">
            We are{" "}
            <span className="text-[#0453BC] text-[4.5vmax] italic font-bold tracking-wide lg:text-[2.5vmax]">
              featured
            </span>{" "}
            in
          </h1>
          <p className="text-[#3F3F3FB5] font-[Qanelas] font-semibold text-[1.7vmax] text-center leading-none lg:text-[1.2vmax]">
            Start your journey to a healthy body weight with the plan that fits
            you most
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-between w-full gap-2 px-5 lg:justify-center lg:gap-12">
          <div>
            <img src={DB} alt="DB" />
          </div>
          <div>
            <img src={TOI} alt="TOI" />
          </div>
          <div>
            <img src={Patrika} alt="Patrika" />
          </div>
          <div>{/* <img src={DBS} alt="DBS" /> */}</div>
        </div>
        <div className="w-full lg:h-14">
          <img
            className="h-full w-full"
            src={FooterBottom}
            alt="FooterBottom"
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
