// "baseURL":"http://139.59.34.177:3011/api",
// "baseURL":"https://ambassador-api.weightwonder.in/api",
function getOptions(token) {
    return {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    };
}

function postOptions(body, token) {
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    };
}

function patchOptions(body, token) {
    return {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    };
}

export function getApiCall(url) {
    const token = JSON.parse(localStorage.getItem("ww_ref_token"))
    return fetch(url, getOptions(token)).then((res) => res.json())
}   

export function postApiCall(url, body) {
    const token = JSON.parse(localStorage.getItem("ww_ref_token"))
    return fetch(url, postOptions(body, token)).then((res) => res.json())
}

export function patchApiCall(url, body) {
    const token = JSON.parse(localStorage.getItem("ww_ref_token"))
    return fetch(url, patchOptions(body, token)).then((res) => res.json())
}

export function _postApiCall(url) {
    const token = JSON.parse(localStorage.getItem("ww_ref_token"))
    return fetch(url, postOptions({}, token))
}