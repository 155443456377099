import { TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
const tm = 7776000000;

const yyyymmdd = (date) => {
    const _date = {
        y: new Date(date).getFullYear(),
        m: new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth(),
        d: new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate()
    }

    return `${_date.y}-${_date.m}-${_date.d}`
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


const today = formatDate(new Date())
const threeMonthsAgo = yyyymmdd(new Date().getTime() - tm)
const oneYearAgo = yyyymmdd(new Date().getTime() - tm * 4)
export default function DatePicker(props) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const inputRef = React.useRef(null);
    const inputRefs = React.useRef(null);
    useEffect(() => {
        if (startDate && endDate) {
            props.getDatas({ startDate: startDate, endDate: endDate });
            // props?.getDatasForApiCall({ startDate: startDate, endDate: endDate });
        }

    }, [startDate, endDate])

    useEffect(() => {

        setStartDate('');
        setEndDate('');

        if (inputRef.current) {
            inputRef.current.value = '';
            inputRefs.current.value = '';
        }

    }, [props.clearDate])
    useEffect(() => {
        if (props.day === 1) {
            inputRef.current.value = threeMonthsAgo;
            inputRefs.current.value = today;
            setStartDate(threeMonthsAgo);
            setEndDate(today)

        }
    }, [])

    return (
        <div className='flex  justify-center items-center '>
            <TextField type='date' size='small' className=' w-40 bg-white'
                inpu={startDate}
                value={startDate}
                inputRef={inputRef}
                InputProps={{ inputProps: { max: endDate } }}
                onChange={(event) => setStartDate(event.target.value)}
            />
            <Typography>-</Typography>
            <TextField
                type='date' size='small' className=' w-40 bg-white' onChange={(event) => setEndDate(event.target.value)}
                value={endDate}
                inputRef={inputRefs}
                InputProps={{ inputProps: { min: startDate } }}
            />
            {
                startDate || endDate ?
                    (<ClearIcon className='text-[#3366ff] cursor-pointer hover:text-black'
                        onClick={() => {
                            props.getDatas({ startDate: '', endDate: '' });
                            setStartDate('');
                            setEndDate('');
                            if (inputRef.current) {
                                inputRef.current.value = '';
                                inputRefs.current.value = '';
                            }

                        }} />) :
                    (<div></div>)
            }
        </div>
    )
}