import React from 'react'
 
import Form from './Form';
import { getApiCall } from "../Services/Api_services";
import constant from "../Services/constant.json";

const DiscountForm = (props) => {
    // async function getDiscount(e) {
    //     try {
    //         const value = props.path;
    //         const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${value}"}}`;
    //         const couponcode = await getApiCall(url)
    //         if (couponcode && couponcode[0].discount) {
    //             setDiscount(couponcode[0].discount)
    //         } else {
    //             console.log("no discount");
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // React.useEffect(() => {
    //     getDiscount();
    // }, [])


    return (
        <>
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.83)" }} 
                className='formcard flex flex-col items-center justify-center gap-5 w-full py-6 rounded-md shadow'>
   
                <Form discount={props?.ambassador?.discount} couponcb={(coupon) => props?.setCoupon(coupon)}  currencyAbbr={props?.ambassador?.currencyAbbr} getAmbassador={props?.getAmbassador} />
            </div>
        </>
    )
}

export default DiscountForm