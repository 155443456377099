import { useNavigate } from "react-router-dom";
import Dynamictable from "../Components/DynamicTable";
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as React from "react";
import { getApiCall } from "../Services/Api_services";
import constant from "../Services/constant.json";
import SearchIcon from "@mui/icons-material/Search";
import debounce from 'lodash/debounce';
function Cases() {
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [allCasesArr, setAllCasesArr] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (search?.length <= 0) {
      fetchCount();
    }
    // fetchCount();
    getAllCases();
    // eslint-disable-next-line
  }, [rowsPerPage, search]);

  async function fetchCount() {
    const where = `{
        "or": [
          {
              "name": {
                  "like": "${search}",
                  "options": "i"
              }
          },
          {
              "email": {
                  "like": "${search}",
                  "options": "i"
              }
          },
          {
              "phone": {
                  "like": "${search}",
                  "options": "i"
              }
          }
        ]
      }`;
    const url = `${constant.baseURL}/cases/count${
      search ? `?where=${where}` : ""
    }`;
    const cases = await getApiCall(url);
    setTotal(cases.count);
  }

  async function getAllCases(skip, limit, newPage) {
    const where = ` "where": {
        "or": [
            {
                "name": {
                    "like": "${search}",
                    "options": "i"
                }
            },
            {
                "email": {
                    "like": "${search}",
                    "options": "i"
                }
            },
            {
                "phone": {
                    "like": "${search}",
                    "options": "i"
                }
            }
        ]
    },`;
    const filter = `{
        ${search ? where : ""}
        "order": "createdAt DESC",
        "skip": ${skip ? skip : 0},
        "limit": ${limit ? limit : rowsPerPage},
        "include": "ambassador"
      }`;
    const url = `${constant.baseURL}/cases?filter=${filter}`;
    const Cases = await getApiCall(url);
    if (search) {
      setTotal(Cases.length);
    }
    let arr = [];
    if (Cases.length === 0) setAllCasesArr([]);
    for (let index = 0; index < Cases.length; index++) {
      const element = Cases[index];
      const dobString = element?.dob;
      const dob = new Date(dobString);
      const resultDob =
        dob.getDate() + "/" + (dob.getMonth() + 1) + "/" + dob.getFullYear();
      const dateString = element?.createdAt;
      const D = new Date(dateString);
      const email = element.email ? element.email : "--";
      const name = element?.name ? element?.name : "--";
      const ambassadorName = element?.ambassador?.name
        ? element?.ambassador?.name
        : "--";
      const gender = element?.gender ? element?.gender : "--";
      const couponCode = element?.couponCode ? element?.couponCode : "--";
      const phone = element?.phone ? element?.phone : "--";
      const result =
        D.getDate() + "/" + (D.getMonth() + 1) + "/" + D.getFullYear();
      arr.push(
        createData(
          index + 1,
          name,
          ambassadorName,
          email,
          gender,
          resultDob,
          couponCode,
          phone,
          result,
          element?.id
        )
      );

      if(index === Cases.length - 1) {
        setAllCasesArr(arr);
        setPage(newPage ? newPage : 0);
      }
    }
  }

  const headCells = [
    {
      id: "first",
      numeric: false,
      disablePadding: false,
      label: "S.no",
    },
    {
      id: "second",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "third",
      numeric: true,
      disablePadding: false,
      label: "Ambassador",
    },
    {
      id: "fourth",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "fifth",
      numeric: true,
      disablePadding: false,
      label: "Gender",
    },
    {
      id: "sixth",
      numeric: true,
      disablePadding: false,
      label: "DOB",
    },
    {
      id: "seventh",
      numeric: true,
      disablePadding: false,
      label: "Coupon code",
    },
    {
      id: "eighth",
      numeric: true,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "ninth",
      numeric: true,
      disablePadding: false,
      label: "Created At",
    },
  ];

  function createData(
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eighth,
    ninth,
    id
  ) {
    return {
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eighth,
      ninth,
      id,
    };
  }

  function rowClick(row, index) {
    navigate(`/admin/caseDetails/${row?.id}/${row?.seventh}`);
    // navigate(`/admin/caseDetails/${row?.id}`);
  }

  const handleChangePage = (event, newPage) => {
    const _skip = newPage * rowsPerPage;
    const limit = rowsPerPage;
    getAllCases(_skip, limit, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
// Debounce function with a delay of 300ms
const debouncedSetSearch = debounce((value) => {
  setSearch(value);
}, 300);

const handleInputChange = (event) => {
  const { value } = event.target;
  // Call the debounced setSearch function
  debouncedSetSearch(value);
};
  return (
    <div className="h-full w-full p-5">
      <div className="flex justify-between items-center">
        <h1 className="text-lg text-gray-500">Cases</h1>
        <FormControl className="searchBar">
          <TextField
            size="small"
            sx={{ backgroundColor: "white" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="sm" />
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            onChange={handleInputChange}
            // onChange={(ev) => {getAllCases(0,50,0,ev.target.value)}}
          />
        </FormControl>
      </div>
      <div className="my-5">
        {allCasesArr ? (
          allCasesArr?.length > 0 ? (
            <Dynamictable
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
              rowClick={(row, index) => rowClick(row, index)}
              heading={headCells}
              rowdata={allCasesArr}
              totalData={total}
            />
          ) : (
            <div className="h-96 flex items-center justify-center">
              <p className="text-lg">No cases yet!</p>
            </div>
          )
        ) : (
          <div className="h-96 flex items-center justify-center">
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Cases;
