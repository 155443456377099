import * as React from "react";
import HeaderLogo from "../assets/image 7.png";
import DiscountForm from "../Components/DiscountForm";
import constant from "../Services/constant.json";
import Composition from "../Components/landing/Composition";
import Footer from "../Components/Footer";
import Guide from "../Components/landing/Guide";
import Rating from "../Components/landing/RatingCard";
import { useLocation } from "react-router-dom";
import Layout from "../Components/Layout";
import { getApiCall } from "../Services/Api_services";

const Home = (props) => {
  const location = useLocation();
  const [ambassadorData, setAmbassadorData] = React.useState("");
  const [path, setPath] = React.useState(
    location.pathname.substring(1)?.toUpperCase()
  );

  async function getAmbassador(e) {
    try {
      const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${path}"}}`;
      const data = await getApiCall(url);
      if (data && data.length) setAmbassadorData(data[0]);
      // const IMG_URL = constant.imageBaseURL + ambassadorData[0].logo
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    getAmbassador();
  }, [path]);

  return (
    <>
      <div className="homeScreen w-full flex flex-col lg:flex-row pt-[2vh]">
        <div className="w-full px-4 lg:w-1/2 py-16 flex flex-col items-start justify-center gap-16 lg:gap-0 lg:h-screen lg:px-8">
          <div className="w-fit flex items-center justify-start">
            <img src={HeaderLogo} alt="HeaderLogo" />
          </div>

          <div className="flex items-center justify-center w-fit lg:h-[70vh]">
            <div className="flex flex-col items-start gap-6">
              <div className="flex flex-col items-start gap-0 lg:gap-3">
                <h1 className="text-[#3F3F3F] font-[Qanelas] font-semibold text-[3.5vmax] lg:leading-none lg:text-[3vmax]">
                  Your Weight Loss Wonder: Personalized Plans, Real Results with
                  a never before offer
                </h1>
                {/* <h1 className="text-[#3F3F3F] font-[Qanelas] font-semibold text-[3.5vmax] lg:leading-none lg:text-[3vmax]">
                  {" "}
                  Embrace The New You!
                </h1> */}
              </div>
              <p className="text-[#3F3F3F] font-normal text-[1.5vmax] leading-5 lg:text-[1vmax] lg:w-[80%] lg:leading-6">
                Struggling to shed those extra pounds? Feeling lost in a sea of
                generic diet plans and workout routines? Weightwonder is here to
                be your weight loss hero!
              </p>
            </div>
          </div>
        </div>
        <div>
          <Layout ambassador={ambassadorData} />
        </div>
        <div className="flex items-center justify-center w-full px-4 lg:w-1/2 lg:px-12">
          <DiscountForm
            ambassador={ambassadorData}
            setCoupon={(coupon) => setPath(coupon)}
            getAmbassador={getAmbassador}
          />
        </div>
      </div>
      <Composition />
      <Rating />
      <Guide />
      <Footer />
    </>
  );
};

export default Home;
