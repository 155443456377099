import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Screens/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Sidebar from "./Components/Sidebar";
import Cases from "./Screens/Cases";
import CaseDetails from "./Screens/CaseDetails";
import Ambassadors from "./Screens/Ambassadors";
import AmbassadorDetails from "./Screens/AmbassadorsDetails";
import CasesForm from "./Screens/CasesForm";
import Thankyou from "./Screens/Thankyou";
import Home from "./Screens/Home";
import Guide from "./Components/landing/Guide";
import Enroll from "./Components/landing/Enroll";

let theme = createTheme({
  typography: {
    fontFamily: ["Poppins", " sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#1479bd",
    },
    action: {
      // active: '#2B44FF',
      // hover: '#2B44FF',
      selected: "#1479bd",
      focus: "#1479bd",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

function App() {
	const tok = localStorage.getItem("ww_ref_token");
	const loginRole = JSON.parse(localStorage.getItem("ww_ref_role"));
  const [token, setToken] = React.useState(tok);
  const [role, setrole] = React.useState(loginRole);

  React.useEffect(() => {
    // const tok = localStorage.getItem("ww_ref_token");
    // setToken(tok);
    // const loginRole = JSON.parse(localStorage.getItem("ww_ref_role"));
    // setrole(loginRole);
  }, [token, role]);

  function refreshToken() {
    const tok = localStorage.getItem("ww_ref_token");
    setToken(tok);
    const loginRole = JSON.parse(localStorage.getItem("ww_ref_role"));
    setrole(loginRole);
  }

	
  return (
    <div className="h-full flex flex-col">
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="/:coupon" element={<Home />} />
              <Route element={<Guide />} path="/guide" />
              <Route element={<Enroll />} path="/enroll" />
              <Route element={<CasesForm />} path="/enroll" />
              <Route element={<Thankyou />} path="/thankyou" />

              {/* Admin */}
              <Route
                element={<Login refreshToken={refreshToken}></Login>}
                path="/admin"
              />
              <Route
                element={<Login refreshToken={refreshToken}></Login>}
                path="/admin/login"
              />
              {token && role === "admin" ? (
                <Route path="/admin" element={<Sidebar />}>
                  <Route path="" element={<Cases />} />
                  <Route path="cases" element={<Cases />} />
                  <Route path="ambassadors" element={<Ambassadors />} />
                  {/* <Route path="caseDetails/:caseId" element={<CaseDetails />} /> */}
                  <Route
                    path="caseDetails/:caseId/:CouponCode"
                    element={<CaseDetails />}
                  />

                  <Route
                    path="AmbassadorDetails/:ambassadorId"
                    element={<AmbassadorDetails />}
                  />
                </Route>
              ) : (
								!token && role === "admin" ?
                <Route
                  path="*"
                  element={<Navigate to="/admin" replace={true} />}
                /> : null
              )}
              {/* Admin */}

              {/* Ambassador */}
              <Route
                element={<Login refreshToken={refreshToken}></Login>}
                path="/ambassador"
              />
              {token && role === "ambassador" ? (
                <Route path="/ambassadors" element={<Sidebar />}>
                  <Route path="" element={<Cases />} />
                  <Route path="cases" element={<Cases />} />
                  {/* <Route path="ambassadors" element={<Ambassadors />} /> */}
                  <Route
                    path="caseDetails/:caseId/:CouponCode"
                    element={<CaseDetails />}
                  />
                  <Route
                    path="AmbassadorDetails/:ambassadorId"
                    element={<AmbassadorDetails />}
                  />
                </Route>
              ) : ( <Route
              path="*"
              element={<Navigate to="/ambassador" replace={true} />}
            />)}
              {/* Ambassador */}
             { token && (role=== "ambassador" || role === "admin" ) &&<Route
                path="caseDetails/:caseId/:CouponCode"
                element={<CaseDetails />}
              />}
              <Route
                element={<Login refreshToken={refreshToken}></Login>}
                path="/"
              />
              {token ? (
                <Route
                  path="/ambassador/:ambassadorId"
                  element={<AmbassadorDetails />}
                />
              ) : null}
              {token ? (
                <Route path="/caseDetails/:caseId" element={<CaseDetails />} />
              ) : null}
            </Routes>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
