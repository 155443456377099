import { NavLink, Outlet, useNavigate } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import constant from "../Services/constant.json";
import { _postApiCall } from "../Services/Api_services";
import CustomSnackbar from "./CustomSnackbar";

const drawerWidth = 240;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     heigth: 100,
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    height: `calc(100%-64px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Sidebar() {
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const appbar = React.useRef(null);
  const [appbarHeight, setAppbarHeight] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    if (windowSize.innerWidth < 900) {
      setOpen(false);
    }
    window.addEventListener("resize", handleWindowResize);
    setAppbarHeight(appbar.current.clientHeight);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, [appbar]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const adminLogout = async () => {
    const url = `${constant.baseURL}/admins/logout`;
    const logout = await _postApiCall(url);
    if (logout?.ok) {
      window.location.replace("/admin");
      localStorage.removeItem("ww_ref_token");
      localStorage.removeItem("ww_ref_userId");
    } else {
      setSnackbar({
        openSnackbar: true,
        message: "Internal server error",
        severity: "error",
      });
    }
  };
  return (
    <div className="h-[100%]">
      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} ref={appbar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: open ? drawerWidth : 0,
            flexShrink: 0,
            // "& .MuiDrawer-paper": {
            //   width: drawerWidth,
            //   boxSizing: "border-box",
            // },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <div className="flex items-center">
              <h1 className="text-lg">Weight Wonder</h1>
              &nbsp;&nbsp;
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
          </DrawerHeader>
          <Divider />
          <List>
            <NavLink to={"/admin/cases"}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CasesOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Cases"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to={"/admin/ambassadors"}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Ambassadors"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>

          <div className="flex flex-col pb-10 justify-end h-full">
            <Button
              className="mr-24 ml-5 text-xl"
              onClick={() => setLogoutDialog(true)}
              variant="text"
            >
              Logout &nbsp; <LogoutIcon sx={{ fontSize: "15px" }} />
            </Button>
          </div>
        </Drawer>
        {/* <Main className="main flex flex-col flex-1" open={open}
          
        >
          <DrawerHeader />
          <div className="bg-gray-100 childH overflow-hidden flex flex-col flex-1">
            <Outlet />
          </div>
        </Main> */}
        <Box
          component="main"
          className="flex flex-1  flex-col "
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: { sm: `calc(100%- ${appbarHeight}px)` },
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <Dialog fullWidth={true} maxWidth="sm" open={logoutDialog}>
        <DialogTitle className="themeColor text-white">LOGOUT</DialogTitle>
        <Divider />
        <DialogContent className="">
          <div>
            <h1>Are you sure you want to logout</h1>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button onClick={() => setLogoutDialog(false)} variant="outlined">
            No
          </Button>
          &nbsp;
          <Button onClick={adminLogout} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        openSnackbar={snackbar.openSnackbar}
        closeSnackbar={() =>
          setSnackbar({
            openSnackbar: false,
            message: "",
            severity: "",
          })
        }
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
}
export default Sidebar;
