import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import constant from "../Services/constant.json";
import { getApiCall, postApiCall } from "../Services/Api_services";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../Components/CustomSnackbar";

function CasesForm() {
  const navigate = useNavigate();
  const [emailErr, setEmailErr] = React.useState(false)
  const [couponCodeErr, setCouponCodeErr] = React.useState(false)
  const [phoneErr, setPhoneErr] = React.useState(false)
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [DOB, setDOB] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [couponCode, setCouponCode] = React.useState("");
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: '',
    severity: ''
  });


  const theme = createTheme({
    components: {
      // Name of the component
      MuiInput: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            color: DOB ? "black" : "gray",
            required: true,
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  async function submit() {
    const body = {
      name: name,
      phone: phone,
      email: email,
      dob: DOB,
      gender: gender,
      couponCode: couponCode,
    };
    const url = `${constant.baseURL}/cases`;
    const addCase = await postApiCall(url, body);
    if (addCase?.id) {
      reset();
      navigate("/thankyou");
    }
    else {
      setSnackbar({
        openSnackbar: true,
        message: 'Internal server error',
        severity: 'error'
      })
    }
  }

  function reset() {
    setName("");
    setPhone("");
    setEmail("");
    setDOB("");
    setGender("");
    setCouponCode("");
  }

  async function changeCoupon(e) {
    const value = e.target.value.toUpperCase();
    setCouponCode(value);
    const url = `${constant.baseURL}/ambassadors/count?where={"couponCode":"${value}"}`
    const couponcode = await getApiCall(url)
    if (couponcode?.count === 1) {
      setCouponCodeErr(false)
    }
    else {
      setCouponCodeErr(true)
    }
  }

  function changePhone(e) {
    setPhone(e.target.value)
    if (e.target.value?.length < 6 || e.target.value?.length > 15) {
      setPhoneErr(true)
    }
    else {
      setPhoneErr(false)
    }
  }

  function changeEmail(e) {
    setEmail(e.target.value)
    const email = validateEmail(e.target.value)
    if (email) {
      setEmailErr(false)
    }
    else {
      setEmailErr(true)
    }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) || email==="";
  };

  return (
      <>
        {/* <h1 className="text-gray-500 text-xl">Add case</h1> */}
        <FormControl fullWidth>
          <TextField
            required
            id="1"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        &nbsp;
        <FormControl fullWidth>
          <TextField
            required
            id="1"
            label="Phone"
            variant="outlined"
            value={phone}
            type="number"
            onChange={changePhone}
          />
          {phoneErr && <FormHelperText className='text-red-600'>
            Phone no. should be of 6 to 15 digits.
          </FormHelperText>}
        </FormControl>
        &nbsp;
        <FormControl fullWidth>
          <TextField
            
            id="1"
            label="Email"
            variant="outlined"
            value={email}
            onChange={changeEmail}
          />
          {emailErr && <FormHelperText className='text-red-600'>
            Email is not valid.
          </FormHelperText>}
        </FormControl>
        &nbsp;
        <ThemeProvider theme={theme}>
          <FormControl fullWidth>
            <FormLabel >DOB <span className='text-red-600'>*</span></FormLabel>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="1"
              variant="outlined"
              type="date"
              value={DOB}
              placeholder="DD/MM/YY"
              onChange={(e) => setDOB(e.target.value)}
            />
          </FormControl>
        </ThemeProvider>
        &nbsp;
        <FormControl fullWidth>
          <InputLabel
            required
            sx={{ marginLeft: "-13px" }}
            id="demo-simple-select-label"
          >
            Gender
          </InputLabel>
          <Select
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
            <MenuItem value={"Others"}>Others</MenuItem>
          </Select>
        </FormControl>
        &nbsp;
        <FormControl fullWidth>
          <TextField
            id="1"
            label="Coupon code"
            variant="outlined"
            value={couponCode}
            onChange={changeCoupon}
          />
          {couponCodeErr && <FormHelperText className='text-red-600'>
            Coupon code don't exist.
          </FormHelperText>}
        </FormControl>
        <div className="flex justify-end mt-7">
          <Button onClick={reset} variant="outlined">
            Reset
          </Button>
          &nbsp;
          <Button disabled={name && phone  && DOB && gender && !couponCodeErr && !phoneErr && !emailErr ? false : true} onClick={submit} variant="contained">
            Submit
          </Button>
        </div>
        <CustomSnackbar
          openSnackbar={snackbar.openSnackbar}
          closeSnackbar={() => setSnackbar({
            openSnackbar: false,
            message: '',
            severity: ''
          })}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      </>
  );
}

export default CasesForm;
