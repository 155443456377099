import Button from "@mui/material/Button";

export default function Diet(props) {
  return (
    <div className="min-h-screen w-full bg-[#FAFAFA]">
      <div className="w-full flex flex-col-reverse lg:flex-row gap-20 bg-[#FAFAFA] py-10">
        <div className="flex-1 flex lg:justify-end justify-center">
          <div className="flex items-start h-full relative">
            <div className="w-full h-[70%] ">
              <img
                src="/images/decor.png"
                alt="decor"
                width={"40%"}
                className="absolute right-0 top-0 "
              />
              <img
                src="images/mob_ww.png"
                alt="mobile"
                className="relative w-full h-full object-contain z-[4] mt-10"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-center text-justify  ">
          <div className="lg:w-[30vw] text-justify p-8">
            <h1 className="text-[#3F3F3F] text-[34px] mb-8 font-bold 2xs:text-[20px]">
              The WW App: Your Transformation Partner in your Pocket
            </h1>
            <p className="lg:text-[14px] mb-8 text-[#3F3F3F] 2xs:text-[12px]">
              The Weightwonder app is your secret weapon in the fight for a
              healthier you. It goes beyond a simple calorie counter, providing
              step-by-step guidance to keep you motivated and on track for fast
              and lasting results.
            </p>
            <p className="lg:text-[14px] text-[#3F3F3F] mb-12 2xs:text-[12px]">
              With the Weightwonder app, you'll never feel lost or alone on your
              weight loss journey. It's a comprehensive tool that provides
              personalized guidance, real-time feedback, and the constant
              support of your coach – all conveniently accessible from your
              phone. Download the Weightwonder app today and unlock the key to
              achieving your weight loss goals and a healthier you!
            </p>
            <div className="flex justify-center items-center lg:justify-start">
              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                className="bg-[#3F3F3F] text-[#ffffff] border-[#3F3F3F] font-semibold "
                variant="outlined"
                size="large"
              >
                GET OFFER
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
