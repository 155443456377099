import Breadcrumb from "../Components/Breadcrumbs";
import Link from "@mui/material/Link";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import { getApiCall } from "../Services/Api_services";
import constant from "../Services/constant.json";
import CasesDialog from "../Popups/CasesDialog";
import EnrollDialog from "../Popups/EnrollDialog";
import KeyValue from "../Components/KeyValue";
import { Box } from "@mui/system";

function CaseDetails() {
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [casesArr, setcasesArr] = React.useState(null);
  const [dob, setDob] = React.useState("");
  const [editCasesDialog, setEditCaseDialog] = React.useState(false);
  const [enrollDialog, setEnrollDialog] = React.useState(false);

  function breadCrumbClick(event) {
    event.preventDefault();
    navigate("/admin/cases");
  }

  let params = useParams();
  const { caseId } = params;
  const role = JSON.parse(localStorage.getItem("ww_ref_role"));

  React.useEffect(() => {
    fetchCount();
    getCase();
    // eslint-disable-next-line
  }, [rowsPerPage]);

  async function getCase(skip, limit, newPage) {
    const filter = `{     
      "include": [         
        {             
          "relation": "ambassador"         
        },         
        {             
          "relation": "enrollments",             
          "scope": {                 
            "order": "createdAt DESC",                 
            "skip":${skip ? skip : 0},                 
            "limit": ${limit ? limit : rowsPerPage}            
          }         
        },
        {             
          "relation": "ambassador",             
          "scope": {                 
            "order": "createdAt DESC",                 
            "skip":${skip ? skip : 0},                 
            "limit": ${limit ? limit : rowsPerPage}            
          }         
        }     
      ] 
    }`;
    const url = `${constant.baseURL}/cases/${caseId}?filter=${filter}`;
    const pCase = await getApiCall(url);
    const dobString = pCase?.dob;
    const dob = new Date(dobString);
    const resultDob =
      dob.getDate() + "/" + (dob.getMonth() + 1) + "/" + dob.getFullYear();
    setDob(resultDob);
    setcasesArr(pCase);
    setPage(newPage ? newPage : 0);
  }

  async function fetchCount() {
    const url = `${constant.baseURL}/cases/${caseId}/enrollments/count`;
    const count = await getApiCall(url);
    setCount(count?.count);
  }
  function reloadData() {
    fetchCount();
    getCase();
  }

  function closeEnrollDialog() {
    setEnrollDialog(false);
  }

  const breadcrumbs = [
    <Link
      className="cursor-pointer text-xl"
      underline="hover"
      key="1"
      color="inherit"
      to="/ambassadorList"
      onClick={breadCrumbClick}
    >
      Cases
    </Link>,
    <Link
      className="cursor-pointer text-xl"
      underline="hover"
      key="2"
      color="inherit"
      to="/"
    >
      {casesArr?.ambassador?.name ? casesArr?.ambassador?.name : "Case Details"}
    </Link>,
  ];

  const handleChangePage = (event, newPage) => {
    const _skip = newPage * rowsPerPage;
    const limit = rowsPerPage;
    getCase(_skip, limit, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function closeDialog() {
    setEditCaseDialog(false);
  }

  const Loader = () => (
    <div className="h-full w-full flex items-center justify-center backdrop-blur-xs bg-white/30 absolute top-0 z-30">
      <CircularProgress size={50} />
    </div>
  );

  return (
    <div className="h-full w-full p-5 relative">
      {casesArr ? null : <Loader />}
      <div>
        {role === "admin" ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : (
          <h1 className="text-2xl">{casesArr?.name}</h1>
        )}
      </div>
      <div className="flex flex-wrap justify-between bg-slate-50 mt-10 shadow p-5 ">
        <div className="flex flex-col justify-between">
          <KeyValue label={"Name"} value={casesArr?.name} />
          <KeyValue label={"Phone"} value={casesArr?.phone} />
          {casesArr?.email ? (
            <KeyValue label={"Email"} value={casesArr?.email} />
          ) : null}
        </div>
        <div className="flex flex-col  ">
          <KeyValue label={"DOB"} value={dob} />
          <KeyValue label={"Gender"} value={casesArr?.gender} />
        </div>
        {role === "admin" && (
          <div>
            <Button onClick={() => setEnrollDialog(true)} variant="contained">
              Enroll
            </Button>
            &nbsp;
            <Button onClick={() => setEditCaseDialog(true)} variant="outlined">
              Edit
            </Button>
          </div>
        )}
       {casesArr?.ambassador?.name && <div className="w-full pt-2">
          <KeyValue
            label={"Ambassador"}
            value={
              <p
                className="text-blue-500 cursor-pointer"
                onClick={() =>
                  navigate( role === "admin" ? 
                    `/admin/AmbassadorDetails/${casesArr?.ambassador?.id}`
                   : `/ambassador/${casesArr?.ambassador?.id}`)
                }
              >
                {casesArr?.ambassador?.name}
              </p>
            }
          />
        </div>}
      </div>
      <h1 className="mt-7 text-gray-500 text-lg">Enrollment history</h1>
      {casesArr?.enrollments?.length > 0 ? (
        <div>
          <div className="mt-8 list h-full overflow-y-auto bg-white">
            <List className="">
              {casesArr?.enrollments?.map((e, i) => {
                const dobString = e?.enrollmentDate;
                const dob = new Date(dobString);
                const resultDob =
                  dob.getDate() +
                  "/" +
                  (dob.getMonth() + 1) +
                  "/" +
                  dob.getFullYear();

                return (
                  <ListItem
                    key={i}
                    secondaryAction={<div> {e?.discountApplied?.toFixed(2)} <span>{casesArr?.ambassador?.currencyAbbr}</span></div>}
                  >
                    <ListItemText
                      primary={
                        <div>
                            <div className="flex gap-2">
                            <Box component="span" sx={{ display: "block", fontWeight:'bold' }}>
                              Case Dob :
                            </Box>
                          <h1>{resultDob}</h1>
                          </div>
                          <div className="flex gap-2">
                            <Box component="span" sx={{ display: "block", fontWeight:'bold' }}>
                              Referral Type :
                            </Box>

                            <h1>{e?.referralType}</h1>
                          </div>
                          <div className="flex gap-2">
                            <Box component="span" sx={{ display: "block", fontWeight:'bold' }}>
                              Referral Amount :
                            </Box>
                          <h1>{e?.referralAmt?.toFixed(2)}</h1> <span>{casesArr?.ambassador?.currencyAbbr}</span>
                          </div>
                        </div>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
          <TablePagination
            className="bg-slate-50 "
            sx={{ color: "#8F95B2", overflow: "hidden" }}
            rowsPerPageOptions={[5, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        <h1 className="text-gray-500 mt-10">No transactions yet.</h1>
      )}
      <CasesDialog
        editCasesDialog={editCasesDialog}
        closeDialog={closeDialog}
        casesArr={casesArr}
        reloadData={reloadData}
      />

      <EnrollDialog
        reloadData={reloadData}
        enrollDialog={enrollDialog}
        couponCode={casesArr?.couponCode}
        closeDilaog={closeEnrollDialog}
        caseId={caseId}
      />
    </div>
  );
}
export default CaseDetails;
