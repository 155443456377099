
const KeyValue = ({ label, value,helperValue }) => (
    <div className="flex flex-col md:flex-row md:items-center gap-1">
        <p className="label text-xs">{label}: </p>
        <div className="flex gap-1">
        <p className="label text-sm text-[#333] font-medium">{value}</p>
        <p className="label text-sm text-[#333] font-medium">{helperValue}</p>
        </div>

    </div>
)

export default KeyValue