import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
gsap.registerPlugin(ScrollTrigger);

const Composition = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const data = [
    {
      sno: "01",
      name: "Body Composition Analysis & Defining Target",
      para: "We'll start by understanding your unique body composition (muscle, fat, water weight) through a bioelectrical impedance analysis (BIA) or similar methods. Together, we'll define realistic and healthy weight loss goals based on your individual needs and overall health.",
      img: "images/Treatment1.png",
    },
    {
      sno: "02",
      name: "Personalized Nutrition Plan & Behavior Change",
      para: "Forget magical drops! We'll create a personalized nutrition plan based on your preferences, dietary restrictions, and activity level. This plan will focus on whole, unprocessed foods that fuel your body and promote healthy eating habits. We'll guide you on behavior changes for long-term success.",
      img: "images/Treatment2.png",
    },
    {
      sno: "03",
      name: "Sustainable Habits for Lasting Results",
      para: "Our program goes beyond just weight loss. We'll help you develop sustainable habits that promote a healthy lifestyle. This may include guidance on exercise routines, stress management, and sleep hygiene – all crucial factors for long-term weight management.",
      img: "images/Treatment3.png",
    },
    {
      sno: "04",
      name: "Weight Maintenance Support",
      para: "Achieving your goal weight is just one step. Weightwonder will continue to support you after you reach your target weight. We'll provide tools and strategies to help you maintain your healthy habits and prevent weight regain.",
      img: "images/Treatment3.png",
    },
  ];

  return (
    <>
      <div className=" w-full flex justify-center items-center">
        <div className="w-full flex flex-col px-6 lg:px-20 gap-4">
          <div className="flex justify-center lg:justify-start py-6 lg:py-12 ">
            <p className="text-4xl font-semibold">
              How does the <br></br>
              <span className="text-[#0453BC] italic">treatment</span> works?
            </p>
          </div>

          <Slider {...settings}>
            {data.map((item, i) => {
              return (
                <div key={i}>
                  <div className="w-full flex flex-col lg:flex-row gap-10 ">
                    <div className="flex-1 flex lg:justify-end justify-center items-center">
                      <img src={item.img} width={"70%"} alt="mobile-image" />
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-center gap-2">
                      <p className="text-[#0453BC] text-[16px] mb-8">
                        {item.sno}
                      </p>
                      <p className="text-[32px] text-[#3F3F3F]">{item.name}</p>
                      <p className="text-[15px] text-[#3F3F3F] mb-4">
                        {" "}
                        {item.para}
                      </p>
                    </div>
                  </div>{" "}
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Composition;
