import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, heading } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {heading.map((headCell) => (
          <TableCell
            sx={{ color: "#8F95B2", minWidth: 170 }}
            className="bg-slate-50"
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function Dynamictable(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box className="flex flex-col flex-1 ">
      <TableContainer className="flex-1">
        <div className="tableHeight">
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            stickyHeader
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              heading={props?.heading}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props?.rowdata?.length}
            />
            <TableBody>
              {props?.rowdata
                .slice()
                .sort(getComparator(order, orderBy))
                .map((row, index) => {
                  return (
                    <TableRow
                      onClick={() => props?.rowClick(row, index)}
                      className="cursor-pointer"
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {row.first && (
                        <TableCell align="left">{row.first}</TableCell>
                      )}
                      {row.second && (
                        <TableCell align="left">{row.second}</TableCell>
                      )}
                      {row.third && (
                        <TableCell align="left">{row.third}</TableCell>
                      )}
                      {row.fourth && (
                        <TableCell align="left">{row.fourth}</TableCell>
                      )}
                      {row.fifth && (
                        <TableCell align="left">{row.fifth}</TableCell>
                      )}
                      {row.sixth && (
                        <TableCell align="left">{row.sixth}</TableCell>
                      )}
                      {row.seventh && (
                        <TableCell align="left">{row.seventh}</TableCell>
                      )}
                      {row.eighth && (
                        <TableCell align="left">{row.eighth}</TableCell>
                      )}

                      {row.ninth && (
                        <TableCell align="left">{row.ninth}</TableCell>
                      )}
                      {row.tenth >= 0 && (
                        <TableCell align="left">{row.tenth}</TableCell>
                      )}
                      {row.eleventh && (
                        <TableCell align="left">{row.eleventh}</TableCell>
                      )}
                      {row.twelth >= 0 && (
                        <TableCell align="left">{row.twelth}</TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <TablePagination
        sx={{ color: "#8F95B2", width: "100%", overflow: "hidden" }}
        className="bg-slate-50"
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={props?.totalData}
        rowsPerPage={props?.rowsPerPage}
        page={props?.page}
        onPageChange={props?.handleChangePage}
        onRowsPerPageChange={props?.handleChangeRowsPerPage}
      />
    </Box>
  );
}
export default Dynamictable;
