import * as React from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Avatar,
  IconButton,
  InputBase,
  Switch,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import purse from "../assets/purse.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import DatePicker from "../Components/DatePicker";
import debounce from "lodash/debounce";

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  Button,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../Components/Breadcrumbs";
import constant from "../Services/constant.json";
import {
  getApiCall,
  _postApiCall,
  postApiCall,
} from "../Services/Api_services";
import AmbassadorDialog from "../Popups/AmbassadorDialog";
import CasesDialog from "../Popups/CasesDialog";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PaymentDialog from "../Popups/PaymentDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDebounce } from "use-debounce";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import moment from "moment";
import KeyValue from "../Components/KeyValue";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AmbassadorsDetails() {
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState("cases");
  const navigate = useNavigate();
  const [transactionsTotal, setTransactionsTotal] = React.useState(0);
  const [casesTotal, setCasesTotal] = React.useState(0);
  const [pageLeft, setPageLeft] = React.useState(0);
  const [transactionPage, setTransactionPage] = React.useState(0);
  const [transactionRowsPerPage, setTransactionRowsPerPage] =
    React.useState(50);
  const [pageRight, setPageRight] = React.useState(0);
  const [rowsPerPageRight, setRowsPerPageRight] = React.useState(50);
  const [ambassadorArr, setAmbassadorArr] = React.useState(null);
  const [editAmbassadorDialog, setEditAmbassadorDialog] = React.useState(false);
  const [addCasesDialog, setAddCaseDialog] = React.useState(false);
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [isDateEmpty, setIsDateEmpty] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });

  const [tableHeight, setTableHeight] = React.useState(0);
  const [dates, setDates] = React.useState({
    // startDate: new Date("2023"),
    // endDate: new Date(),
    startDate: "",
    endDate: "",
  });
  const containerRef = React.useRef(null);
  let params = useParams();
  const { ambassadorId } = params;

  const role = JSON.parse(localStorage.getItem("ww_ref_role"));

  function closeDialog() {
    setAddCaseDialog(false);
  }

  function reloadData() {
    if (!search && isDateEmpty === false) {
      // getTransactionsCount();
    }
    getCasesCount();
    getAmbassador();
  }

  // GET TRANSECTIONS 
  const getTransactions = async (skip, limit, newPage, data) => {
    setLoading(true);

    const searchFilter = {
      id: ambassadorId,
      text: search,
      dates: data || dates,
      skip: skip,
      limit: limit,
    };
    const dateFilter = {
      id: ambassadorId,
      dates: data || dates,
      skip: skip,
      limit: limit,
    };
    const filter = searchFilter.text ? searchFilter : dateFilter;
    const url = `${constant.baseURL}/ambassadors/getTransactions`;
    const transactions = await postApiCall(url, filter);
    setTransactionsTotal(transactions?.totalCount)
    setTransactions(transactions?.data[0]?.transactions);
    setLoading(false);
  };


  async function getAmbassador(skip, limit, newPage) {
    const filter = `{
      "include": [
          {
              "relation": "cases",
              "scope": {
               
                  "include": [
                      {
                          "relation": "enrollments",
                          "scope": {
                              "order": "createdAt DESC"
                              
                          }
                      }
                  ],
                  "order": "createdAt DESC",
                  "skip": ${skip ? skip : 0},
                  "limit": ${limit ? limit : rowsPerPageRight}
              }
          }
      ]
  }`;
    const url = `${constant.baseURL
      }/ambassadors/${ambassadorId}?filter=${filter?.trim()}`;
    const ambassador = await getApiCall(url, { id: ambassadorId });
    setAmbassadorArr(ambassador);

    setPageRight(newPage ? newPage : 0);
  }

  async function getTransactionsCount() {
    // const url = `${constant.baseURL}/ambassadors/${ambassadorId}/transactions/count?where={"and":[{"createdAt":{"lte":"2024-03-02"}},{"createdAt":{"gte":"2024-02-01"}}]}`;
    const url = `${constant.baseURL}/ambassadors/${ambassadorId}/transactions/count`;
    const count = await getApiCall(url);
    setTransactionsTotal(count?.count);
  }

  async function getCasesCount() {
    const url = `${constant.baseURL}/ambassadors/${ambassadorId}/cases/count`;
    const count = await getApiCall(url);
    setCasesTotal(count?.count);
  }

  const breadcrumbs = [
    <Link
      className="cursor-pointer breadcrumb"
      underline="hover"
      key="1"
      color="inherit"
      to=""
      onClick={breadCrumbClick}
    >
      Ambassadors
    </Link>,
    <Link
      className="cursor-pointer breadcrumb"
      underline="hover"
      key="2"
      color="inherit"
      to=""
    >
      {ambassadorArr?.logo ? (
        <div className="flex items-center gap-2">
          <Avatar
            src={`${constant.baseURL}/uploads/logo/download/${ambassadorArr?.logo}`}
            alt="ambassador logo"
            width={40}
          />
          <Typography>{ambassadorArr?.name}</Typography>
        </div>
      ) : (
        ambassadorArr?.name
      )}
    </Link>,
  ];

  function breadCrumbClick(event) {
    event.preventDefault();
    navigate("/admin/ambassadors");
  }

  const handleChangePageTransaction = (event, newPage) => {
    const _skip = newPage * transactionRowsPerPage;
    const limit = transactionRowsPerPage;
    setTransactionPage(newPage);
    getTransactions(_skip, limit, newPage);
  };

  const handleChangeRowsPerPageTransaction = (event) => {
    setTransactionRowsPerPage(parseInt(event.target.value, 10));
    setTransactionPage(0);
    getTransactions(0, event.target.value, 0);
  };

  const handleChangePageRight = (event, newPage) => {
    const _skip = newPage * rowsPerPageRight;
    const limit = rowsPerPageRight;
    getAmbassador(_skip, limit, newPage);
  };

  const handleChangeRowsPerPageRight = (event) => {
    setRowsPerPageRight(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setEditAmbassadorDialog(false);
  };

  const editAmbassador = () => {
    setEditAmbassadorDialog(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ambassadorLogout = async () => {
    const url = `${constant.baseURL}/ambassadors/logout`;
    const logout = await _postApiCall(url);
    console.log(logout);
    if (logout?.ok) {
      window.location.replace("/ambassador");
      localStorage.clear();
    } else {
      setSnackbar({
        openSnackbar: true,
        message: "Internal server error",
        severity: "error",
      });
    }
  };

  const getDatas = async (data) => {
    if (data.startDate && data.endDate) {
      if (data.startDate?.length === 0 && data.endDate?.length === 0) {
        setDates(data);
        // setIsDateEmpty(false);
      } else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
        setDates(data);
        // setIsDateEmpty(false);
      }
    } else {
      setDates(data);
    }
  };

  const Loader = () => (
    <div className="h-full w-full flex items-center justify-center backdrop-blur-xs bg-white/30 absolute top-0 z-30">
      <CircularProgress size={50} />
    </div>
  );
  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
  }, 300);

  const handleInputChange = (event) => {
    const { value } = event.target;
    debouncedSetSearch(value);
  };

  React.useEffect(() => {
    if (search || (dates?.startDate && dates?.endDate)) {
      setTransactionPage(0);
      getTransactions(0, 50, transactionRowsPerPage);
    }
  }, [search, dates]);

  React.useEffect(() => {
    setTableHeight(containerRef?.current?.offsetHeight);
  }, [containerRef?.current?.offsetHeight]);

  React.useEffect(() => {
    if (!search && dates?.endDate === "") {
      getTransactions(0, 50, transactionRowsPerPage); 
      // change by Arbaz Khan multiple appi calling
    }
    getCasesCount();
    // getTransactionsCount();
    getAmbassador();
  }, [rowsPerPageRight, transactionRowsPerPage, search, dates]);

  // change by Arbaz Khan create a function for call api on refresh
  React.useEffect(() => {
    getTransactions(0, 50, transactionRowsPerPage);
  }, [])


  return (
    <div className="text-[Lufga] p-5 flex flex-col gap-6 flex-1 h-full">
      {!ambassadorArr ? <Loader /> : null}
      {role !== "admin" ? (
        <div className="flex justify-between">
          <div className="flex flex-col font-Lufga">
            <h2 className="text-[#060EE2] font-semibold ">WEIGHTWONDER</h2>
            <p className="text-[#676767] font-medium">AMBASSADOR</p>
          </div>
          <div>
            <LogoutOutlinedIcon
              sx={{ color: "red", cursor: "pointer" }}
              onClick={() => setLogoutDialog(true)}
            />
          </div>
        </div>
      ) : null}

      <div>
        {role === "admin" ? (
          <div className="flex justify-between">
            <div>
              <Breadcrumb breadcrumbs={breadcrumbs} />
            </div>
            <div>
              {role === "admin" && (
                <Button
                  onClick={() => setPaymentDialog(true)}
                  className=""
                  variant="contained"
                >
                  Release Payments
                </Button>
              )}
              &nbsp;&nbsp;
              {role === "admin" && (
                <Button
                  onClick={editAmbassador}
                  className=""
                  variant="outlined"
                >
                  Edit
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex">
            <img
              src={constant.imageBaseURL + ambassadorArr?.logo}
              alt="Logo"
              className="w-16"
            />
            <p className="p-1 font-bold text-3xl">{ambassadorArr?.username}</p>
          </div>
        )}
      </div>

      <div className="flex flex-col md:flex-row gap-4">
        <div
          className="flex flex-col gap-3 bg-[#FFFFFF] justify-between rounded-md shadow-xl px-4 py-3 lg:w-2/3 md:flex-row"
          sx={{ boxShadow: 3 }}
        >
          <div className="flex-2">
            <p className="text-sm label  mb-4">Basic Details</p>
            <div className="mb-1">
              <PhoneIcon fontSize="sm" /> {ambassadorArr?.phone}
            </div>
            <div className="flex items-center gap-1">
              <EmailIcon fontSize="sm" />{" "}
              <Typography className="font-[Lufga]">
                {" "}
                {ambassadorArr?.email}{" "}
              </Typography>
            </div>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem />
          <div className="flex-1">
            <p className="text-sm label mb-4">Coupon Details</p>
            <div className="flex flex-col gap-1">
              <div className="flex flex-col">
                {ambassadorArr?.couponCode ||
                  ambassadorArr?.couponCode !== 0 ? (
                  <KeyValue
                    label="Coupon"
                    value={
                      <span className="active">
                        {ambassadorArr?.couponCode}
                      </span>
                    }
                  />
                ) : null}
                {ambassadorArr?.discount || ambassadorArr?.discount !== 0 ? (
                  <KeyValue
                    label="Referral Discount"
                    value={ambassadorArr?.discount?.toFixed(2)}
                    helperValue={ambassadorArr?.currencyAbbr}
                  />
                ) : null}
                {ambassadorArr?.defReferralAmt ||
                  ambassadorArr?.defReferralAmt !== 0 ? (
                  <KeyValue
                    label="Default Referral Amount"
                    value={ambassadorArr?.defReferralAmt?.toFixed(2)}
                    helperValue={ambassadorArr?.currencyAbbr}
                  />
                ) : null}
                {ambassadorArr?.altReferralAmt ||
                  ambassadorArr?.altReferralAmt !== 0 ? (
                  <KeyValue
                    label="Alternate Referral Amount"
                    value={ambassadorArr?.altReferralAmt?.toFixed(2)}
                    helperValue={ambassadorArr?.currencyAbbr}
                  />
                ) : null}
                {ambassadorArr?.reEnrollReferralFactor ||
                  ambassadorArr?.reEnrollReferralFactor !== 0 ? (
                  <KeyValue
                    label="Alternate Referral Amount"
                    value={ambassadorArr?.reEnrollReferralFactor}
                    helperValue={ambassadorArr?.currencyAbbr}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className=" bg-[#FFFFFF] rounded-md shadow-xl p-4 lg:w-1/3"
          sx={{ boxShadow: 3 }}
        >
          <p className="text-sm label">Wallet Balance</p>
          <div className="flex   justify-between mt-8">
            <p className="text-[#0CCC08] font-bold text-5xl">
              <span> + {ambassadorArr?.wallet?.toFixed(2)} </span>

              <span> {ambassadorArr?.currencyAbbr}</span>
            </p>
            <img src={purse} alt="purse" width={60} className="-mt-10" />
          </div>
        </div>
      </div>
      <Box className="flex-1 flex flex-col gap-4">
        <div className="flex flex-col gap-3 justify-between md:flex-row w-full">
          <div>
            <AppBar
              position="static"
              sx={{ borderColor: "divider" }}
              className="w-64   bg-white rounded-sm shadow-xl my-1"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#3E00F0",
                  },
                }}
              >
                <Tab
                  label="Cases"
                  className="text-[#0000005C] normal-case"
                  {...a11yProps(0)}
                  onClick={() => {
                    setTab("cases");
                  }}
                />
                <Tab
                  className="text-[#0000005C] normal-case"
                  label="Transactions"
                  {...a11yProps(1)}
                  onClick={() => {
                    setTab("transaction");
                  }}
                />
                getAmbassador
              </Tabs>
            </AppBar>
          </div>

          {tab === "cases" ? (
            <div
              className="flex justify-center items-center absolute right-10 bg-[#3E00F0] text-center rounded-sm p-2  w-[15] cursor-pointer"
              onClick={() => setAddCaseDialog(true)}
            >
              <Typography className="text-[#fff] font-semibold">
                Add New Case
              </Typography>
              <AddIcon
                sx={{ fontSize: "30px", color: "white", textAlign: "center" }}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-3 md:flex-row">
              <Box
                size="small"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: 1,
                  borderRadius: 1,
                  borderColor: "#9E9E9E78",
                  marginY: 1,
                }}
              >
                <IconButton>
                  <SearchIcon />
                </IconButton>
                <InputBase
                  placeholder="Search"
                  // onChange={(e) => setSearch(e.target.value)}
                  onChange={handleInputChange}
                />
              </Box>
              <DatePicker
                getDatas={getDatas}
              // getDatasForApiCall={(e) => {
              //   getDatasForApiCall(e);
              // }}
              />
            </div>
          )}
        </div>
        <div ref={containerRef} className="flex flex-col flex-1 shadow-xl ">
          {
            <TabPanel value={value} index={0}>
              {ambassadorArr?.cases?.length > 0 ? (
                <>
                  <TableContainer
                    sx={{
                      minHeight: tableHeight - 53,
                      maxHeight: tableHeight - 53,
                      // maxHeight: 502,
                      position: "relative",
                      boxShadow: "none",
                    }}
                    component={Paper}
                  >
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-[#00000087]">
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-[#00000087]"
                          >
                            Mobile no.
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-[#00000087]"
                          >
                            Amount referred
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-[#00000087]"
                          >
                            Date-Time
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ambassadorArr?.cases?.map((c, i) => {
                          const referralAmt = [...c.enrollments];
                          let referralAmtCount = 0;
                          for (
                            let index = 0;
                            index < referralAmt.length;
                            index++
                          ) {
                            const element = referralAmt[index];
                            referralAmtCount =
                              referralAmtCount + element?.referralAmt;
                          }
                          return (
                            <TableRow
                              onClick={
                                role === "admin"
                                  ? () =>
                                    navigate(
                                      `/admin/caseDetails/${c?.id}/${c?.couponCode}`
                                    )
                                  : () =>
                                    navigate(
                                      `/caseDetails/${c?.id}/${c?.couponCode}`
                                    )
                              }
                              className="cursor-pointer"
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell component="th" scope="row">
                                {c?.name}
                              </TableCell>
                              <TableCell align="center">{c?.phone}</TableCell>
                              <TableCell align="center">
                                {referralAmtCount ? (
                                  <div>
                                    {referralAmtCount?.toFixed(2)}
                                    {ambassadorArr?.currencyAbbr}
                                  </div>
                                ) : (
                                  "NA"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {moment(c?.createdAt).format("llll")}{" "}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="bg-slate-50 h-[52px]"
                    sx={{
                      color: "#8F95B2",
                      overflow: "hidden",
                      paddingLeft: "-20px",
                    }}
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={casesTotal}
                    rowsPerPage={rowsPerPageRight}
                    page={pageRight}
                    onPageChange={handleChangePageRight}
                    onRowsPerPageChange={handleChangeRowsPerPageRight}
                  />
                </>
              ) : (
                <h1 className="text-gray-500 mt-10">No cases yet.</h1>
              )}
            </TabPanel>
          }
          <TabPanel value={value} index={1}>
            {transactions?.length > 0 ? (
              <>
                <TableContainer
                  sx={{
                    minHeight: tableHeight - 52,
                    maxHeight: tableHeight - 52,
                    // maxHeight: 502,
                    // position: "relative",
                    boxShadow: "none",
                  }}
                  component={Paper}
                >
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="text-[#00000087]">Name</TableCell>
                        <TableCell align="center" className="text-[#00000087]">
                          Type
                        </TableCell>
                        <TableCell align="center" className="text-[#00000087]">
                          Mobile no.
                        </TableCell>
                        <TableCell align="center" className="text-[#00000087]">
                          Amount
                        </TableCell>
                        <TableCell align="center" className="text-[#00000087]">
                          Date-Time
                        </TableCell>
                        {/* <TableCell align="right" className="text-[#00000087]">Status</TableCell> */}
                      </TableRow>
                    </TableHead>
                    {/* Panel for all transactions */}
                    {loading ? (
                      <div className=" pt-6">
                        <Loader />
                      </div>
                    ) : (
                      <TableBody>
                        {transactions?.map((t, i) => {
                          return (
                            <>
                              {t?.amount ? (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    border: "none",
                                  }}
                                >
                                  <TableCell>
                                    {t?.enrollments?.case?.name
                                      ? t?.enrollments?.case?.name
                                      : "NA"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t?.txnType}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t?.enrollments?.case?.phone ? (
                                      t?.enrollments?.case?.phone
                                    ) : (
                                      <span className="text-center">
                                        {"NA"}
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {t.txnType === "debit" ? (
                                      <h1 className="text-red-600">
                                        -{t?.amount?.toFixed(2)}{" "}
                                        {ambassadorArr?.currencyAbbr}
                                      </h1>
                                    ) : (
                                      <h1 className="text-green-600">
                                        +{t?.amount?.toFixed(2)}{" "}
                                        {ambassadorArr?.currencyAbbr}
                                      </h1>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {moment(t.createdAt).format("llll")}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  className="bg-slate-50 h-[52px]"
                  sx={{ color: "#8F95B2", overflow: "hidden" }}
                  rowsPerPageOptions={[50, 100, 250]}
                  component="div"
                  count={transactionsTotal}
                  page={transactionPage}
                  rowsPerPage={transactionRowsPerPage}
                  onPageChange={handleChangePageTransaction}
                  onRowsPerPageChange={handleChangeRowsPerPageTransaction}
                />
              </>
            ) : (
              <h1 className="text-gray-500 mt-10">No transaction yet.</h1>
            )}
          </TabPanel>
        </div>
      </Box>

      <div className="h-0">
        <AmbassadorDialog
          reloadData={reloadData}
          ambassadorArr={ambassadorArr}
          editAmbassadorDialog={editAmbassadorDialog}
          handleClose={handleClose}
          getTransactions={getTransactions}
        />
        <CasesDialog
          addCasesDialog={addCasesDialog}
          closeDialog={closeDialog}
          reloadData={reloadData}
          ambassadorArr={ambassadorArr}
        />
        <PaymentDialog
          paymentDialog={paymentDialog}
          getTransactions={getTransactions}
          closeDialog={() => setPaymentDialog(false)}
          walletAmount={ambassadorArr?.wallet}
          ambassadorId={ambassadorId}
          reloadData={reloadData}
        />
        <Dialog fullWidth={true} maxWidth="sm" open={logoutDialog}>
          <DialogTitle className="themeColor text-white">LOGOUT</DialogTitle>
          <Divider />
          <DialogContent className="">
            <div>
              <h1>Are you sure you want to logout</h1>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions className="my-2">
            <Button onClick={() => setLogoutDialog(false)} variant="outlined">
              No
            </Button>
            &nbsp;
            <Button onClick={ambassadorLogout} variant="contained">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
