import * as React from "react";
import {
  Button,
  DialogActions,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, FormHelperText } from "@mui/material";
import constant from "../Services/constant.json";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getApiCall, postApiCall } from "../Services/Api_services";
import CustomSnackbar from "../Components/CustomSnackbar";

function EnrollDialog(props) {
  const [date, setDate] = React.useState("");
  const [referralType, setReferralType] = React.useState("");
  const [referralAmount, setReferralAmount] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [factor, setFactor] = React.useState("");
  const [discountErr, setDiscountErr] = React.useState(false);
  const [referralAmountErr, setReferralAmtErr] = React.useState(false);
  const [debouncedClick, setDebouncedClick] = React.useState(null);
  const [loading,setLoading]=React.useState(false)
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });

  const theme = createTheme({
    components: {
      // Name of the component
      MuiInput: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            color: date ? "black" : "gray",
            required: true,
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  async function changeReferralType(e) {
    setReferralType(e.target.value);
    const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${props?.couponCode}"}}`;
    const ambassador = await getApiCall(url);
    if (e.target.value === "default") {
      setReferralAmount(ambassador[0]?.defReferralAmt);
      setReferralAmtErr(false);
    } else {
      setReferralAmount(ambassador[0]?.altReferralAmt);
      setReferralAmtErr(false);
    }
  }

  async function changeFactor(e) {
    setFactor(e.target.value);
    const url = `${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${props?.couponCode}"}}`;
    const ambassador = await getApiCall(url);
    if (e.target.value === "first") {
      setDiscount(ambassador[0]?.discount);
      setDiscountErr(false);
    } else {
      setDiscount(
        (
          ambassador[0]?.discount * ambassador[0]?.reEnrollReferralFactor
        )?.toFixed(2)
      );
      setDiscountErr(false);
    }
  }
  function changeDiscount(e) {
    setDiscount(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setDiscountErr(false);
    } else {
      setDiscountErr(true);
    }
  }
  function changeReferral(e) {
    setReferralAmount(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setReferralAmtErr(false);
    } else {
      setReferralAmtErr(true);
    }
  }
  function onActionClose() {
    setDate("");
    setReferralType("");
    setReferralAmount("");
    setDiscount("");
    setFactor("");
    setDiscountErr(false);
    setReferralAmtErr(false);
    props?.closeDilaog();
  }
  const debounce = (func, delay) => {
    clearTimeout(debouncedClick);
    const newDebouncedClick = setTimeout(func, delay);
    setDebouncedClick(newDebouncedClick);
  };
  const enrollCase = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const body = {
          enrollmentDate: date,
          referralType: referralType,
          enrollmentType: factor,
          discountApplied: parseFloat(discount),
          referralAmt: parseFloat(referralAmount),
          caseId: props?.caseId,
        };
        const url = `${constant.baseURL}/enrollments`;
        const enroll = await postApiCall(url, body);
        setLoading(false)
        if (enroll?.id) {
          setSnackbar({
            openSnackbar: true,
            message: "Case enrolled successfully",
            severity: "success",
          });
          onActionClose();
          props?.reloadData();
        } else {
          setSnackbar({
            openSnackbar: true,
            message: "Internal server error",
            severity: "error",
          });
        }
      } catch (error) {
        setLoading(false)
        reject(error);
      }
    });
  }, [date, referralType, factor, discount, referralAmount, props]);

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="sm" open={props?.enrollDialog}>
        <DialogTitle className="themeColor text-white">ADD ENROLL</DialogTitle>
        <Divider />
        <DialogContent className="pb-10">
          <div>
            <ThemeProvider theme={theme}>
              <FormControl className="mt-4" fullWidth>
                <FormLabel>
                  Date <span className="text-red-600">*</span>
                </FormLabel>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  id="1"
                  variant="standard"
                  type="date"
                  value={date}
                  placeholder="DD/MM/YY"
                  onChange={(e) => setDate(e.target.value)}
                />
              </FormControl>
            </ThemeProvider>
            &nbsp;
            <FormControl fullWidth>
              <InputLabel
                required
                sx={{ marginLeft: "-13px" }}
                id="demo-simple-select-label"
              >
                Referral type
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={referralType}
                label="Gender"
                onChange={changeReferralType}
              >
                <MenuItem value={"default"}>Default</MenuItem>
                <MenuItem value={"alternative"}>Alternative</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <InputLabel
                required
                sx={{ marginLeft: "-13px" }}
                id="demo-simple-select-label"
              >
                Factor
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={factor}
                label="Gender"
                onChange={changeFactor}
              >
                <MenuItem value={"first"}>First</MenuItem>
                <MenuItem value={"reenroll"}>Re-enrollment</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <TextField
                required
                id="2"
                // type='number'
                label="Referral"
                variant="standard"
                value={referralAmount}
                onChange={changeReferral}
                error={referralAmountErr ? true : false}
              />
              {referralAmountErr && (
                <FormHelperText className="text-red-600">
                  Amout should be less than 1000000
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <TextField
                required
                id="3"
                // type="number"
                label="Discount"
                variant="standard"
                value={discount}
                onChange={changeDiscount}
                error={discountErr ? true : false}
              />
              {discountErr && (
                <FormHelperText className="text-red-600">
                  Amout should be less than 1000000
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button onClick={onActionClose} variant="outlined">
            Cancel
          </Button>
          &nbsp;
          <Button
            onClick={enrollCase}
            // onClick={() => debounce(enrollCase, 1000)}
            disabled={
              !loading&&
              date &&
              referralType &&
              referralAmount &&
              discount &&
              factor &&
              !referralAmountErr &&
              !discountErr
                ? false
                : true
            }
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        openSnackbar={snackbar.openSnackbar}
        closeSnackbar={() =>
          setSnackbar({
            openSnackbar: false,
            message: "",
            severity: "",
          })
        }
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
}
export default EnrollDialog;
