import * as React from "react";
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import data from "../../Services/userData.json";
import Rating from "@mui/material/Rating";
// import { Grid } from '@mui/material';

export default function RatingCard() {
  const [value, setValue] = React.useState(2);

  return (
    <>
      <div className=" items-center gap-5 justify-center  bg-[#FAFAFA] lg:items-center xl:items-center 2xl:items-center  px-5">
        <div className="py-16">
          <h1 className="text-[40px] font-semibold pl-2 text-left">
            What our <span className="text-[#0453BC] italic">client</span> says?
          </h1>
        </div>
        <div className="testemonials flex gap-6 md:row-span-1 lg:flex-row py-4   2xs:flex  items-center overflow-x-auto">
          {data.map((user, i) => {
            return (
              <Card
                sx={{
                  maxWidth: 345,
                  minHeight: 300,
                  maxHeight: 300,
                  padding: 2,
                  flexShrink: 0,
                }}
                key={i}
              >
                <div className="lg:flex   2xs:flex flex-col">
                  <CardMedia
                    sx={{ height: 40, width: 40 }}
                    image={user.img}
                    title="green iguana"
                  />

                  <Rating name="read-only" value={value} readOnly />
                </div>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign={"justify"}
                  >
                    {user.desc.length > 200
                      ? `${user.desc.slice(0, 200)}......`
                      : user.desc}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontSize={12}
                    marginTop={2}
                  >
                    {user.name}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
}
