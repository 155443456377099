import * as React from "react";
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import constant from "../Services/constant.json";
import {
  getApiCall,
  patchApiCall,
  postApiCall,
} from "../Services/Api_services";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomSnackbar from "../Components/CustomSnackbar";
import { StringWithSpace } from "../Regex/Regex";

function CasesDialog(props) {
  const [couponCodeErr, setCouponCodeErr] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState(false);
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [DOB, setDOB] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [phoneExist, setPhoneExist] = React.useState(false);
  const [emailExist, setEmailExist] = React.useState(false);
  const [couponCode, setCouponCode] = React.useState("");
  const [debouncedClick, setDebouncedClick] = React.useState(null);
  const [invalidCoupon, setInvalidCoupon] = React.useState(false);
  const [editCouponCode, setEditCouponCode] = React.useState("");
  const [loading, setLoading] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });

  React.useEffect(() => {
    if (props?.editCasesDialog) {
      const arr = props?.casesArr;
      setName(arr?.name);
      setPhone(arr?.phone);
      setEmail(arr?.email);
      setCouponCode(arr?.couponCode);

      const dobString = arr?.dob;
      const dob = new Date(dobString);
      const resultDob =
        dob.getFullYear() +
        "-" +
        prefixZero(dob.getMonth() + 1) +
        "-" +
        prefixZero(dob.getDate());
      setDOB(resultDob);
      setGender(arr?.gender);
    } else {
      setCouponCode(props?.ambassadorArr?.couponCode);
    }
    // eslint-disable-next-line
  }, [props?.editCasesDialog]);

  const prefixZero = (_num) => {
    if (_num < 10) return "0" + _num;
    else return _num;
  };

  const theme = createTheme({
    components: {
      // Name of the component
      MuiInput: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            color: DOB ? "black" : "gray",
            required: true,
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  const update = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const url = encodeURI(`${constant.baseURL}/ambassadors?filter={"where":{"couponCode":"${editCouponCode}"}}`);
        const res = await getApiCall(url);
        console.log(res)
        const body = {
          name: name,
          phone: phone,
          email: email,
          dob: DOB,
          gender: gender,
          couponCode: editCouponCode,
          ambassadorId: res[0]?.id
        };
        const emailAlreadyExist = await emailExistErr();
        const mobAlreadyExist = await phoneExistErr();
        if (emailAlreadyExist) {
          setSnackbar({
            openSnackbar: true,
            message: "Email aleady registered",
            severity: "error",
          });
          return reject(new Error("Email already registered"));
        }
        if (mobAlreadyExist) {
          setSnackbar({
            openSnackbar: true,
            message: "Phone already registered",
            severity: "error",
          });
          return reject(new Error("Phone already registered"));
        }
        if (!mobAlreadyExist && !emailAlreadyExist) {
          const url = `${constant.baseURL}/cases/${props?.casesArr?.id}`;
          const updateCase = await patchApiCall(url, body);
          setLoading(false)
          if (updateCase?.id) {
            setSnackbar({
              openSnackbar: true,
              message: "Case updated successfully",
              severity: "success",
            });
            onCancelAction();
            props?.reloadData();
            resolve(updateCase)
          } else {
            setSnackbar({
              openSnackbar: true,
              message: "Internal server error",
              severity: "error",
            });
            reject(new Error("Internal server error"));
          }
        }
      } catch (error) {
        setLoading(false)
        reject(error);
      }
    });
  }, [name, phone, email, DOB, gender, props, editCouponCode]);

  function reset() {
    setName("");
    setPhone("");
    setEmail("");
    setDOB("");
    setGender("");
    setEmailExist(false);
    setPhoneExist(false);
    setCouponCodeErr(false);
    setInvalidCoupon(false);
    // setCouponCode("");
  }
  async function changeCoupon(e) {
    const value = e.target.value.toUpperCase();
    if (value.includes("%")) {
      setInvalidCoupon(true);
    } else {
      setInvalidCoupon(false);
      const url = `${constant.baseURL}/ambassadors/count?where={"couponCode":"${value}"}`;
      const res = await getApiCall(url);
      if (res?.count === 1) {
        setCouponCodeErr(false);
      } else {
        setCouponCodeErr(true);
      }
    }
    setEditCouponCode(value);
  }

  function onCancelAction() {
    reset();
    props?.closeDialog();
  }

  const debounce = (func, delay) => {
    clearTimeout(debouncedClick);
    const newDebouncedClick = setTimeout(func, delay);
    setDebouncedClick(newDebouncedClick);
  };
  const addCase = React.useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const body = {
          name: name,
          phone: phone,
          email: email,
          dob: DOB,
          gender: gender,
          couponCode: props?.ambassadorArr?.couponCode,
          // id:props?.ambassadorArr?.id
        };

        const emailAlreadyExist = await emailExistErr();
        const mobAlreadyExist = await phoneExistErr();

        if (emailAlreadyExist) {
          setSnackbar({
            openSnackbar: true,
            message: "Email already registered",
            severity: "error",
          });
          return reject(new Error("Email already registered"));
        }

        if (mobAlreadyExist) {
          setSnackbar({
            openSnackbar: true,
            message: "Phone already registered",
            severity: "error",
          });
          return reject(new Error("Phone already registered"));
        }

        if (!emailAlreadyExist && !mobAlreadyExist) {
          const url = `${constant.baseURL}/cases`;
          const addCaseResponse = await postApiCall(url, body);
          setLoading(false)
          if (addCaseResponse?.id) {
            setSnackbar({
              openSnackbar: true,
              message: "Case added successfully",
              severity: "success",
            });
            onCancelAction();
            props?.reloadData();
            resolve(addCaseResponse);
          } else {
            setSnackbar({
              openSnackbar: true,
              message: "Internal server error",
              severity: "error",
            });
            reject(new Error("Internal server error"));
          }
        }
      } catch (error) {
        setLoading(false)
        reject(error);
      }
    });
  }, [name, phone, email, DOB, gender, props]);

  // async function addCase() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const body = {
  //         name: name,
  //         phone: phone,
  //         email: email,
  //         dob: DOB,
  //         gender: gender,
  //         couponCode: props?.ambassadorArr?.couponCode,
  //         // id:props?.ambassadorArr?.id
  //       };
  //       const emailAlreadyExist = await emailExistErr();
  //       const mobAlreadyExist = await phoneExistErr();
  //       if (emailAlreadyExist) {
  //         setSnackbar({
  //           openSnackbar: true,
  //           message: "Email already registered",
  //           severity: "error",
  //         });
  //         return;
  //       }
  //       if (mobAlreadyExist) {
  //         setSnackbar({
  //           openSnackbar: true,
  //           message: "Phone already registered",
  //           severity: "error",
  //         });
  //         return;
  //       }
  //       if(!emailAlreadyExist && !mobAlreadyExist) {
  //         const url = `${constant.baseURL}/cases`;
  //         const addCase = await postApiCall(url, body);
  //         if (addCase?.id) {
  //           setSnackbar({
  //             openSnackbar: true,
  //             message: "Case added successfully",
  //             severity: "success",
  //           });
  //           onCancelAction();
  //           props?.reloadData();
  //         } else {
  //           setSnackbar({
  //             openSnackbar: true,
  //             message: "Internal server error",
  //             severity: "error",
  //           });
  //         }
  //       }
  //     }
  //     catch (e) {
  //       reject(e)
  //   }

  // }
  //   )}

  function changePhone(e) {
    setPhoneExist(false);
    setPhone(e.target.value);
    if (e.target.value?.length < 6 || e.target.value?.length > 15) {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
    }
  }

  async function phoneExistErr() {
    return new Promise(async (resolve, reject) => {
      try {
        if (phone) {
          const url = `${constant.baseURL}/cases?filter={"where":{"phone":"${phone}"}}`;
          const phoneexist = await getApiCall(url);
          if (phoneexist.length > 0) {
            // setPhoneExist(true);
            if (props?.editCasesDialog) {
              if (props.casesArr.phone?.toString() == phone?.toString()) {
                setPhoneExist(false);
                resolve(false);
              } else {
                setPhoneExist(true);
                resolve(true);
              }
            } else {
              setPhoneExist(true);
              resolve(true);
            }
          } else {
            setPhoneExist(false);
            setLoading(false)
            resolve(false);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  async function emailExistErr() {
    return new Promise(async (resolve, reject) => {
      try {
        if (email) {
          const url = `${constant.baseURL}/cases?filter={"where":{"email":"${email}"}}`;
          const emailexist = await getApiCall(url);
          if (emailexist.length > 0) {
            // setEmailExist(true);
            if (props?.editCasesDialog) {
              if (props.casesArr.email === email) {
                setEmailExist(false);
                resolve(false);
              } else {
                setEmailExist(true);
                resolve(true);
              }
            } else {
              setEmailExist(true);
              resolve(true);
            }
          } else {
            setEmailExist(false);
            resolve(false);
          }
        } else {
          resolve(false);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  function changeEmail(e) {
    setEmailExist(false);
    setEmail(e.target.value);
    const email = validateEmail(e.target.value);
    if (email) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  }
  const validateEmail = (email) => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) || email === ""
    );
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={
          props?.editCasesDialog
            ? props?.editCasesDialog
            : props?.addCasesDialog
        }
      >
        <DialogTitle className="themeColor text-white">
          {props?.addCasesDialog ? "ADD CASE" : "EDIT CASE"}
        </DialogTitle>
        <Divider />
        <DialogContent className="pb-10">
          <div>
            <FormControl fullWidth>
              <TextField
                required
                id="1"
                label="Name"
                variant="standard"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={name?.length > 0 && !StringWithSpace?.test(name)}
                helperText={
                  name?.length > 0 && !StringWithSpace?.test(name)
                    ? "enter valid name"
                    : ""
                }
              />
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <TextField
                required
                id="1"
                label="Phone"
                variant="standard"
                value={phone}
                type="number"
                onChange={changePhone}
                onBlur={phoneExistErr}
              />
              {phoneErr && (
                <FormHelperText className="text-red-600">
                  Phone no. should be of 6 to 15 digits.
                </FormHelperText>
              )}
              {phoneExist && (
                <FormHelperText className="text-red-600">
                  Phone already exist
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <TextField
                id="1"
                label="Email"
                variant="standard"
                value={email}
                onBlur={emailExistErr}
                onChange={changeEmail}
              />
              {emailErr && (
                <FormHelperText className="text-red-600">
                  Email is not valid.
                </FormHelperText>
              )}
              {emailExist && (
                <FormHelperText className="text-red-600">
                  Email already exist
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;
            <ThemeProvider theme={theme}>
              <FormControl fullWidth>
                <FormLabel>
                  DOB <span className="text-red-600">*</span>
                </FormLabel>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="1"
                  variant="standard"
                  type="date"
                  value={DOB}
                  onChange={(e) => setDOB(e.target.value)}
                />
              </FormControl>
            </ThemeProvider>
            &nbsp;
            <FormControl fullWidth>
              <InputLabel
                required
                sx={{ marginLeft: "-13px" }}
                id="demo-simple-select-label"
              >
                Gender
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Gender"
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
            <FormControl fullWidth>
              <TextField
                disabled={props?.editCasesDialog && couponCode ? true : false}
                id="1"
                label="Coupon code"
                variant="standard"
                value={
                  props?.editCasesDialog && couponCode
                    ? couponCode
                    : props?.editCasesDialog && couponCode
                      ? editCouponCode
                      : props?.ambassadorArr?.couponCode
                }
                onChange={changeCoupon}
                error={invalidCoupon}
                helperText={invalidCoupon ? "Coupon is not valid" : ""}
              />
              {props?.editCasesDialog && couponCodeErr && (
                <FormHelperText className="text-red-600">
                  Coupon code does not exist.
                </FormHelperText>
              )}
            </FormControl>
            {/* <FormControl fullWidth>
              <TextField
                required
                disabled={props?.editCasesDialog ? true : false}
                id="1"
                label="Coupon code"
                variant="standard"
                value={couponCode}
                onChange={changeCoupon}
              />
              {couponCodeErr && <FormHelperText className='text-red-600'>
                Coupon code don't exist.
              </FormHelperText>}
            </FormControl> */}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button onClick={onCancelAction} variant="outlined">
            Cancel
          </Button>
          &nbsp;
          {props?.addCasesDialog ? (
            <Button
              disabled={
                !loading &&
                  name &&
                  phone &&
                  DOB &&
                  gender &&
                  StringWithSpace?.test(name) &&
                  !phoneErr &&
                  !emailErr &&
                  !phoneExist &&
                  !emailExist
                  ? false
                  : true
              }
              onClick={() => addCase()}
              // onClick={() => debounce(addCase, 1000)}
              variant="contained"
            >
              Add
            </Button>
          ) : (
            <Button
              disabled={
                name &&
                  !loading &&
                  phone &&
                  DOB &&
                  gender &&
                  StringWithSpace?.test(name) &&
                  !phoneErr &&
                  !emailErr &&
                  !phoneExist &&
                  !emailExist &&
                  !invalidCoupon &&
                  !couponCodeErr
                  ? false
                  : true
              }
              onClick={() => update()}
              // onClick={() => debounce(update, 1000)}
              variant="contained"
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        openSnackbar={snackbar.openSnackbar}
        closeSnackbar={() =>
          setSnackbar({
            openSnackbar: false,
            message: "",
            severity: "",
          })
        }
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
}
export default CasesDialog;
