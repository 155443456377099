import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function Thankyou() {
  return (
    <div className="bg-gray-100 h-full w-full flex items-center justify-center">
      <div className=" ">
        <h1 className="text-2xl">Thank you for registering with us <CheckCircleOutlineIcon className='text-green-600 text-3xl' /></h1>
      </div>
    </div>
  );
}

export default Thankyou;
