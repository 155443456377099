import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, FormControl, FormHelperText, Input } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import NativeSelect from "@mui/material/NativeSelect";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import constant from "../Services/constant.json";
import {
  getApiCall,
  patchApiCall,
  postApiCall,
} from "../Services/Api_services";
import CustomSnackbar from "../Components/CustomSnackbar";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StringWithSpace, amountRegux } from "../Regex/Regex";
function AmbassadorDialog(props) {
  const [currencyErr, setCurrencyErr] = React.useState(false);
  const [emailExistErr, setEmailExistErr] = React.useState(false);
  const [phoneExistErr, setPhoneExistErr] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState(false);
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [reEnrollFacErr, setReEnrollFacErr] = React.useState(false);
  const [walletBalErr, setWalletBalErr] = React.useState(false);
  const [defReferralAmtErr, setDefReferralAmtErr] = React.useState(false);
  const [altReferralAmtErr, setAltReferralAmtErr] = React.useState(false);
  const [discountErr, setDiscountErr] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [couponCodeErr, setCouponCodeErr] = React.useState(false);
  const [invalidCoupon, setInvalidCoupon] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [couponCode, setCouponCode] = React.useState("");
  const [logo, setLogo] = React.useState({ preview: "", data: "" });
  const adminId = JSON.parse(localStorage.getItem("ww_ref_userId"));
  const [currency, setCurrency] = React.useState("");
  const [defReferralAmt, setDefReferralAmt] = React.useState("");
  const [altReferralAmt, setAltReferralAmt] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [walletBal, setWalletBal] = React.useState("");
  const [reEnrollFac, setReEnrollFac] = React.useState("");
  const [debouncedClick, setDebouncedClick] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({
    openSnackbar: false,
    message: "",
    severity: "",
  });
  const currencyArr = [
    { currency: "INR" },
    { currency: "AUD" },
    { currency: "EUR" },
    { currency: "BDT" },
    { currency: "IDR" },
    { currency: "KWD" },
    { currency: "DJF" },
    { currency: "EGP" },
    { currency: "GYD" },
    { currency: "IDR" },
    { currency: "UAH" },
    { currency: "EGP" },
    { currency: "BRL" },
    { currency: "BYR" },
    { currency: "STD" },
    { currency: "SRD" },
    { currency: "THB" },
    { currency: "UAH" },
    { currency: "YER" },
    { currency: "ZMK" },
  ];
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const uploadLogo = async (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: "",
    };
    let formData = new FormData();
    formData.append("file", img.data);
    const UPLOAD_URL = `${constant.baseURL}/uploads/logo/upload`;
    const res = await fetch(UPLOAD_URL, {
      method: "POST",
      body: formData,
    });
    const response = await res.json();
    if (response.result?.files.file[0]["name"]) {
      const name = response.result?.files.file[0]["name"];
      img.name = name;
      img.preview = `${constant.baseURL}/uploads/logo/download/${name}`;
    }
    setLogo(img);
    // setLogo(URL.createObjectURL(e.target.files[0]));
  };
  const crossLogo = async (name) => {
    try {
      const IMG_REMOVE_URL = `${constant.baseURL}/uploads/logo/files/${name}`;
      const res = await fetch(IMG_REMOVE_URL, {
        method: "DELETE",
      });
      const response = await res.json();
      setLogo({ preview: "", data: "" });
    } catch (e) {
      console.log(`ERR WHILE REMOVING LOGO ${e}`);
    }
  };
  const imageRef = React.useRef(null);
  React.useEffect(() => {
    if (props?.editAmbassadorDialog) {
      const arr = props?.ambassadorArr;
      setName(arr?.name);
      setPhone(arr?.phone);
      setEmail(arr?.email);
      setCouponCode(arr?.couponCode);
      setCurrency(arr?.currencyAbbr);
      setDefReferralAmt(arr?.defReferralAmt);
      setAltReferralAmt(arr?.altReferralAmt);
      setDiscount(arr?.discount);
      setWalletBal(arr?.wallet);
      setReEnrollFac(arr?.reEnrollReferralFactor);
    }
    // eslint-disable-next-line
  }, [props?.editAmbassadorDialog]);

  const debounce = (func, delay) => {
    clearTimeout(debouncedClick);
    const newDebouncedClick = setTimeout(func, delay);
    setDebouncedClick(newDebouncedClick);
  };
  const addAmbassador = React.useCallback(async() => {
      try {
         setLoading(true);
        const body = {
          name: name,
          phone: phone,
          email: email,
          password: values.password,
          couponCode: couponCode,
          currencyAbbr: currency,
          defReferralAmt: parseFloat(defReferralAmt ? defReferralAmt : 0),
          altReferralAmt: parseFloat(altReferralAmt ? altReferralAmt : 0),
          discount: parseFloat(discount),
          wallet: parseFloat(walletBal),
          reEnrollReferralFactor: parseFloat(reEnrollFac ? reEnrollFac : 0),
          // username: name,
          logo: logo.name,
        };
        if (props?.editAmbassadorDialog) {
          if (values.password?.length === 0) {
            delete body.password;
          }
          updateAmbassador(body);
          // debounce(updateAmbassador(body), 1000)
          // debounce(updateAmbassador(body), 1000);
          {
            console.log(body);
          }
        } else {
          if (!currencyErr && !reEnrollFacErr && !couponCodeErr) {
            const url = `${constant.baseURL}/ambassadors`;
            const addAmbassador = await postApiCall(url, body);
            setLoading(false);
            if (addAmbassador.id) {
              const Transactionbody = {
                amount: walletBal,
                ambassadorId: addAmbassador.id,
                txnType: "credit",
                adminId: adminId,
              };
              const transactionUrl = `${constant.baseURL}/transactions`;
              const payout = await postApiCall(transactionUrl, Transactionbody);
              setSnackbar({
                openSnackbar: true,
                message: "Ambassador added successfully",
                severity: "success",
              });
              onCancel();
              setLogo({ preview: "", data: "" });
              props?.reloadData();
            } else {
              setSnackbar({
                openSnackbar: true,
                message: "Internal server error",
                severity: "error",
              });
            }
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
  }, [name, phone, email, values, couponCode,defReferralAmt,altReferralAmt,reEnrollFac, currency,discount,walletBal,logo]);
  const updateAmbassador = async (body) => {
    try {
      const url = `${constant.baseURL}/ambassadors/${props?.ambassadorArr?.id}`;
      const updatedAmbassador = await patchApiCall(url, body);
  
      if (updatedAmbassador.id) {
        const walletDifference = Math.abs(
          parseFloat(walletBal) - parseFloat(props?.ambassadorArr?.wallet)
        );
  
        if (walletDifference !== 0) {
          const transactionBody = {
            amount: walletDifference,
            ambassadorId: props?.ambassadorArr.id,
            txnType: walletBal > props?.ambassadorArr?.wallet ? "credit" : "debit",
            adminId: adminId,
          };
  
          const transactionUrl = `${constant.baseURL}/transactions`;
          const payout = await postApiCall(transactionUrl, transactionBody);
        }
  
        setSnackbar({
          openSnackbar: true,
          message: "Ambassador updated successfully",
          severity: "success",
        });
        props?.getTransactions()
        onCancel();
        setLogo({ preview: "", data: "" });
        props?.reloadData();
      } else {
        setSnackbar({
          openSnackbar: true,
          message: "Internal server error",
          severity: "error",
        });
      }
    } catch (error) {
      console.error('Error updating ambassador:', error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  }
  function onCancel() {
    clearForm();
    props?.handleClose();
  }

  function clearForm() {
    setName("");
    setPhone("");
    setEmail("");
    setValues({
      password: "",
      showPassword: false,
    });
    setCouponCode("");
    setCurrency("");
    setDefReferralAmt("");
    setAltReferralAmt("");
    setDiscount("");
    setWalletBal("");
    setReEnrollFac("");
    setCouponCodeErr("");
    setReEnrollFacErr("");
    setPhoneErr("");
    setEmailErr("");
    setPhoneExistErr("");
    setEmailExistErr("");
    setCurrencyErr("");
    setLogo({ preview: "", data: "" });
  }

  // function changeCurrency(e) {
  //   const value = e.target.value.toUpperCase();
  //   if (e.target.value.length === 3) {
  //     setCurrencyErr(false);
  //     setCurrency(value);
  //   } else {
  //     setCurrencyErr(true);
  //     setCurrency(value);
  //   }
  // }
  const changeCurrencyAbb = (event) => {
    setCurrency(event.target.value);
  };

  function changeReEnrollFac(e) {
    setReEnrollFac(e.target.value);
    if (e.target.value <= 1 && e.target.value >= 0) {
      setReEnrollFacErr(false);
    } else {
      setReEnrollFacErr(true);
    }
  }
  function changeWalletBal(e) {
    setWalletBal(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setWalletBalErr(false);
    } else {
      setWalletBalErr(true);
    }
  }
  function changeDefReferralAmt(e) {
    setDefReferralAmt(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setDefReferralAmtErr(false);
    } else {
      setDefReferralAmtErr(true);
    }
  }
  function changeAltReferralAmt(e) {
    setAltReferralAmt(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setAltReferralAmtErr(false);
    } else {
      setAltReferralAmtErr(true);
    }
  }
  function changeDiscount(e) {
    setDiscount(e.target.value);
    if (e.target.value <= 1000000 && e.target.value >= 0) {
      setDiscountErr(false);
    } else {
      setDiscountErr(true);
    }
  }
  async function changeCouponCode(e) {
    const value = e.target.value.toUpperCase();
    if (value.includes("%")) {
      setInvalidCoupon(true);
    } else {
      setInvalidCoupon(false);
    }
    setCouponCode(value);
  }
  async function couponCodeExist() {
    const url = encodeURI(
      `${constant.baseURL}/ambassadors/count?where={"couponCode":"${couponCode}"}`
    );
    const urlCoupon = `${constant.baseURL}/ambassadors/${props?.ambassadorArr?.id}`;
    const existUser = await getApiCall(urlCoupon);
    const couponcode = await getApiCall(url);

    if (couponcode?.count === 1 && existUser.couponCode !== couponCode) {
      setCouponCodeErr(true);
    } else {
      setCouponCodeErr(false);
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  function changePhone(e) {
    setPhone(e.target.value);
    if (e.target.value?.length < 6 || e.target.value?.length > 15) {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
    }
  }

  function changeEmail(e) {
    setEmail(e.target.value);
    const email = validateEmail(e.target.value);
    if (email) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async function phoneAlreadyExist() {
    const url = `${constant.baseURL}/ambassadors/count?where={"phone":"${phone}"}`;
    const urlPhone = `${constant.baseURL}/ambassadors/${props?.ambassadorArr?.id}`;
    const phoneNo = await getApiCall(url);
    const existUser = await getApiCall(urlPhone);

    if (phoneNo?.count === 1 && existUser.phone !== phone) {
      setPhoneExistErr(true);
    } else {
      setPhoneExistErr(false);
    }
  }

  async function emailAlreadyExist() {
    const url = `${constant.baseURL}/ambassadors/count?where={"email":"${email}", "phone": {"neq": "${phone}"}}`;
    const urlEmail = `${constant.baseURL}/ambassadors/${props?.ambassadorArr?.id}`;
    const existUser = await getApiCall(urlEmail);
    const existEmail = await getApiCall(url);
    if (existEmail?.count === 1 && existUser.email !== email) {
      setEmailExistErr(true);
    } else {
      setEmailExistErr(false);
    }
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={
          props?.ambassadorDialog
            ? props?.ambassadorDialog || false
            : props?.editAmbassadorDialog || false
        }
      >
        <DialogTitle className="themeColor text-white">
          {props?.ambassadorDialog ? "Add Ambassador" : "Edit Ambassador"}
        </DialogTitle>
        <Divider />
        <DialogContent className="pb-10">
          <div className="flex">
            <FormControl fullWidth>
              <TextField
                required
                id="1"
                label="Name"
                variant="standard"
                value={name}
                type="text"
                error={name?.length > 0 && !StringWithSpace?.test(name)}
                helperText={
                  name?.length > 0 && !StringWithSpace?.test(name)
                    ? "enter valid name"
                    : ""
                }
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            &nbsp;&nbsp;
            <FormControl fullWidth>
              <TextField
                id="2"
                required
                label="Phone"
                variant="standard"
                type="number"
                value={phone}
                onChange={changePhone}
                onBlur={phoneAlreadyExist}
                // onBlur={phoneAlreadyExist}
              />

              {phoneErr && (
                <FormHelperText className="text-red-600">
                  Phone no. should be of 6 to 15 digits.
                </FormHelperText>
              )}
              {phoneExistErr && (
                <FormHelperText className="text-red-600">
                  Phone no. already exist.
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="flex mt-4">
            <FormControl fullWidth>
              <TextField
                id="3"
                required
                label="Email"
                variant="standard"
                value={email}
                onChange={changeEmail}
                onBlur={emailAlreadyExist}
              />
              {emailErr && (
                <FormHelperText className="text-red-600">
                  Email is not valid.
                </FormHelperText>
              )}
              {emailExistErr && (
                <FormHelperText className="text-red-600">
                  Email already exist.
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                required={props?.ambassadorDialog}
              >
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="flex mt-4">
            <FormControl fullWidth>
              <TextField
                id="4"
                required
                label="Coupon code"
                variant="standard"
                value={couponCode}
                onChange={changeCouponCode}
                onBlur={couponCodeExist}
                // error={couponCodeErr ? true : false}
                error={invalidCoupon}
                helperText={invalidCoupon ? "Coupon is not valid" : ""}
              />
              {couponCodeErr && (
                <FormHelperText className="text-red-600">
                  Coupon code already exist.
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <FormControl variant="standard" fullWidth required>
              <InputLabel id="demo-simple-select-standard-label">
                Currency
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Currency"
                value={currency}
                onChange={changeCurrencyAbb}
              >
                {currencyArr.map((data, i) => {
                  return (
                    <MenuItem
                      key={i}
                      className="cursor-pointer"
                      value={data.currency}
                      // onClick={() => {
                      //   setCurrency(data.currency);
                      // }}
                    >
                      {data.currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <FormControl fullWidth>
              <TextField
                id="5"
                required
                label="Currency"
                variant="standard"
                error={currencyErr ? true : false}
                value={currency}
                onChange={changeCurrency}
              />
              {currencyErr && (
                <FormHelperText className="text-red-600">
                  Currency should be of three letters
                </FormHelperText>
              )}
            </FormControl> */}
          </div>
          <div className="flex mt-4">
            <FormControl fullWidth>
              <TextField
                id="6"
                required
                label="Default Referral Amount"
                variant="standard"
                value={defReferralAmt}
                onChange={changeDefReferralAmt}
                error={defReferralAmtErr ? true : false}
              />
              {defReferralAmtErr && (
                <FormHelperText className="text-red-600">
                  Amout should be less than 1000000
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <FormControl fullWidth>
              <TextField
                id="7"
                label="Alternate Referral Amount"
                variant="standard"
                value={altReferralAmt}
                onChange={changeAltReferralAmt}
                error={altReferralAmtErr ? true : false}
              />
              {altReferralAmtErr && (
                <FormHelperText className="text-red-600">
                  Amout should be less than 1000000
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="flex mt-4">
            <FormControl fullWidth>
              <TextField
                id="8"
                required
                label="Discount"
                variant="standard"
                value={discount}
                onChange={changeDiscount}
                error={discountErr ? true : false}
              />
              {discountErr && (
                <FormHelperText className="text-red-600">
                  Amout should be less than 1000000
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <FormControl fullWidth>
              <TextField
                id="9"
                required
                label="Wallet Balance"
                variant="standard"
                error={walletBalErr ? true : false}
                value={walletBal}
                onChange={changeWalletBal}
                // onChange={(e) => setWalletBal(e.target.value)}
              />
              {walletBalErr && (
                <FormHelperText className="text-red-600">
                  Amout should be 1 to 1000000
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="flex mt-4">
            <FormControl className="w-1/2 pr-1">
              <TextField
                id="10"
                label="Re-enrollment factor"
                variant="standard"
                value={reEnrollFac}
                onChange={changeReEnrollFac}
                error={reEnrollFacErr ? true : false}
              />
              {reEnrollFacErr && (
                <FormHelperText className="text-red-600">
                  Value should be between 0 to 1
                </FormHelperText>
              )}
            </FormControl>
            &nbsp;&nbsp;
            <div className="flex justify-between items-center flex-1">
              {logo.data ? (
                <div className="relative">
                  <ClearIcon
                    onClick={() => crossLogo(logo.name)}
                    className="absolute -top-2 -right-2 cursor-pointer"
                  />
                  <img src={logo.preview} alt="logo" width={150} />
                </div>
              ) : null}
              {!logo.data ? (
                <FormControl className="w-1/2 pr-1 pt-3">
                  <Button
                    component="label"
                    id="file-upload"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => {
                      imageRef.current.click();
                    }}
                  >
                    Upload Logo
                  </Button>
                  <VisuallyHiddenInput
                    type="file"
                    ref={imageRef}
                    onChange={uploadLogo}
                    className="file-upload"
                  />
                </FormControl>
              ) : null}
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className="my-2">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          {props?.ambassadorDialog ? (
            <Button
              disabled={
                !loading&&
                name &&
                phone &&
                email &&
                values.password &&
                couponCode &&
                currency &&
                discount &&
                walletBal &&
                walletBal >= 0 &&
                defReferralAmt &&
                !walletBalErr &&
                !defReferralAmtErr &&
                !altReferralAmtErr &&
                !discountErr &&
                !currencyErr &&
                !reEnrollFacErr &&
                !couponCodeErr &&
                !phoneErr &&
                !phoneExistErr &&
                !emailExistErr &&
                !invalidCoupon
                  ? false
                  : true
              }
              variant="contained"
              // onClick={addAmbassador}
              onClick={() => addAmbassador()}
            >
              Add
            </Button>
          ) : (
            <Button
              disabled={
                name &&
                !loading &&
                phone &&
                email &&
                couponCode &&
                currency &&
                discount &&
                currency &&
                walletBal >= 0 &&
                defReferralAmt &&
                !walletBalErr &&
                !defReferralAmtErr &&
                !altReferralAmtErr &&
                !discountErr &&
                !currencyErr &&
                !reEnrollFacErr &&
                !couponCodeErr &&
                !phoneErr &&
                !phoneExistErr &&
                !emailExistErr &&
                !invalidCoupon
                  ? false
                  : true
              }
              // onClick={addAmbassador}
              onClick={() =>addAmbassador()}
              variant="contained"
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        openSnackbar={snackbar.openSnackbar}
        closeSnackbar={() =>
          setSnackbar({
            openSnackbar: false,
            message: "",
            severity: "",
          })
        }
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </div>
  );
}
export default AmbassadorDialog;
